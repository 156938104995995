import {createSlice, type SliceCaseReducers} from '@reduxjs/toolkit'
import type {RejectedActionFromAsyncThunk} from '@reduxjs/toolkit/dist/matchers'
import {addOrEditCompanyThunk} from '../thunks/addOrEditCompanyThunk'
interface CompanyState {
  success: boolean
  error: RejectedActionFromAsyncThunk<any> | null
  loading: boolean
}

const initialState: CompanyState = {
  success: false,
  error: null,
  loading: false
}
export const companySlice = createSlice<CompanyState, SliceCaseReducers<CompanyState>, string>({
  name: 'companies',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(addOrEditCompanyThunk.pending, (state) => {
      return {
        ...state,
        loading: true
      }
    })
    builder.addCase(addOrEditCompanyThunk.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
        success: true
      }
    })
    builder.addCase(addOrEditCompanyThunk.rejected, (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    })
  }
})
