export const paths = {
  home: '/',
  website: 'https://leadsowl.net',
  login: '/login',
  logout: '/logout',
  signUp: '/signUp',
  pricing: '/pricing',
  thankYou: '/thank-you',
  checkout: {
    basePath: '/checkout',
    basic: '/checkout/basic',
    premium: '/checkout/premium',
    custom: '/checkout/custom'
  },
  dashboard: '/dashboard',
  companies: {
    basePath: '/companies',
    new: '/companies/new'
  },
  employees: {
    basePath: '/employees',
    new: '/employees/new'
  },
  industries: {
    basePath: '/industries',
    new: '/industries/new'
  },
  users: {
    basePath: '/users',
    new: '/users/new'
  }
}
