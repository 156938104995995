import {configureStore, type ThunkDispatch} from '@reduxjs/toolkit'
import {authSlice} from './slices'
import {imagesSlice} from './slices/imagesSlice'
import {companySlice} from './slices/companySlice'
import {employeesSlice} from './slices/employeesSlice'
import {cartSlice} from './slices/cartSlice'

export const store = configureStore({
  reducer: {
    authReducer: authSlice.reducer,
    imageReducer: imagesSlice.reducer,
    companyReducer: companySlice.reducer,
    employeeReducer: employeesSlice.reducer,
    cartReducer: cartSlice.reducer,
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunkDispatch = ThunkDispatch<any, any, any>
