import {createSlice} from '@reduxjs/toolkit'
import type {RejectedActionFromAsyncThunk} from '@reduxjs/toolkit/dist/matchers'
import {getCookie} from '../../utils/getCookie'
import {expirationTokenAuth, getUserRole, getUserCredits} from '../../utils/decodeToken'
import {authThunk} from '../thunks/auth.thunk'

// Define a type for the slice state
// Define the initial state using that type

interface AuthState {
  isAuth: boolean
  success: boolean
  error: RejectedActionFromAsyncThunk<any> | null
  accessToken: string | undefined | null
  isExpired: boolean | null
  loading: boolean,
  user_role?: string
  user_credits?: number | null
}

const accessToken = getCookie('accessToken')

const initialState: AuthState = {
  isAuth: accessToken !== undefined
    ? !expirationTokenAuth(accessToken)
    : false,
  success: accessToken !== undefined,
  error: null,
  accessToken: accessToken !== undefined
    ? accessToken
    : null,
  isExpired: accessToken !== undefined
    ? expirationTokenAuth(accessToken)
    : null,
  loading: false,
  user_role: accessToken !== undefined
    ? getUserRole(accessToken)
    : '',
  user_credits: accessToken !== undefined
    ? getUserCredits(accessToken) : null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: (state: AuthState) => {
      state.accessToken = null
      state.isAuth = false
      state.user_role = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(authThunk.pending, (state: AuthState) => {
      return {
        ...state,
        loading: true
      }
    })
    builder.addCase(authThunk.fulfilled, (state: AuthState, action) => {
      return {
        ...state,
        loading: false,
        success: true,
        accessToken: action.payload.data.accessToken,
        isAuth: true,
        user_role: action.payload.data.user_role,
        user_credits: action.payload.data.user_credits
      }
    })
    builder.addCase(authThunk.rejected, (state: AuthState, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    })
  }
})

export const { logOut } = authSlice.actions
