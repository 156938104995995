export const Strings = {
  homePage: {
    title: 'Home Page'
  },
  app: {
    delete: 'Delete',
    edit: 'Edit'
  },
  buttons: {
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    submit: 'Submit',
    back: 'Back'
  },
  images: {
    uploadImage: 'Upload Image',
    uploadImageSuccess: 'Successfully uploaded image',
    uploadImageError: 'Failed to upload image',
    type: 'image/*',
    imageUploadQuestion: 'Do you want to upload this image?',
    deleteImageSuccess: 'Successfully deleted image',
    validations: {
      deleteImage: 'Do you want to delete this image?'
    },
    comp_logo: 'Company Logo'
  },
  errorPage: {
    error: '404 ERROR',
    message: 'Page not found',
    backHome: 'Back to home page'
  },
  dashboardPage: {
    title: 'Dashboard',
    subtitle: 'Welcome to your Admin',
    credits: 'Credits',
    leads: 'Leads',
    recentLeads: 'Recent Leads',
    leadsList: 'Leads List'
  },
  navBarMenu: {
    companies: 'Companies',
    dashboard: 'Dashboard',
    login: 'Login',
    logout: 'Logout',
    signUp: 'Sign Up',
    title: 'SoGo Admin',
    employees: 'Employees',
    industries: 'Industries',
  },
  loginPage: {
    title: 'Login',
    username: 'Username',
    email: 'Email',
    password: 'Password',
    dontHaveAccount: 'Don\'t have an account? Sign Up',
    validations: {
      email: 'This field must be a valid email',
      required: 'This field is required',
      minPasswordLength: 'Password must be at least 6 characters',
      maxPasswordLength: 'Password must be at most 20 characters',
      successLogin: 'Successfully logged in',
      errorLogin: 'Error logging in'
    }
  },
  signUpPage: {
    title: 'Sign Up',
    confirmPassword: 'Confirm Password',
    haveAccount: 'Already have an account? Sign In',
    email: 'Email',
    password: 'Password',
    username: 'Username',
    validations: {
      email: 'This field must be a valid email',
      required: 'This field is required',
      minPasswordLength: 'Password must be at least 6 characters',
      maxPasswordLength: 'Password must be at most 20 characters',
      confirmPassword: 'Password must match',
      successRegisterAccount: 'Account created Successfully'
    },
    labels: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      password: 'Password'
    }
  },
  companyPage: {
    title: 'Company',
    addCompany: 'Add Company',
    editCompany: 'Edit Company',
    companyList: 'Companies',
    addBulkCompanies: 'Add Bulk Companies',
    companyForm: {
      title: 'Add Company',
      name: 'Name',
      industry: 'Industry',
      address: 'Address',
      location: 'Location',
      companyUrl: 'Company URL',
      postalCode: 'Postal Code',
      companySize: 'Company Size',
      companyEmail: 'Company Email',
      companyPhone: 'Company Phone',
      companyLinkedIn: 'Company LinkedIn',
      companyCountry: 'Company Country',
      comp_profile_url: 'Company Logo',
    },
    deleteCompany: 'Delete Company',
    deleteCompanyQuestion: ' Do you want to delete this company?',
    editCompanyQuestion: ' Do you want to edit this company?',
    addBulkCompaniesQuestion: ' Do you want to add multiple companies?',
    validations: {
      required: 'This field is required',
      successBulkUploadCompanies: 'Successfully added multiple companies',
      fileExtensionNotAccepted: 'File extension not accepted, please upload a JSON file',
      deleteCompanySuccess: 'Successfully deleted company',
      successAddCompany: 'Successfully added company',
      successEditCompany: 'Successfully edited company',
      errorAddingCompany: 'Error adding company'
    }
  },
  employeePage: {
    title: 'Employee',
    addEmployee: 'Add Employee',
    editEmployee: 'Edit Employee',
    employeesForm: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      phone: 'Phone',
      country: 'Country',
      title: 'Title',
      linkedIn: 'LinkedIn',
      company: 'Company'
    },
    validations: {
      required: 'This field is required',
      onlyText: 'This field must be only text',
      errorFetchingEmployeeName: 'Could not fetch employee name',
      successDeleteEmployee: 'Successfully deleted employee',
      successAddEmployee: 'Successfully added employee',
      successEditEmployee: 'Successfully edited employee'
    },
    deleteEmployee: 'Delete Employee',
    deleteEmployeeQuestion: ' Do you want to delete this employee?',
    editEmployeeQuestion: ' Do you want to edit this employee?'
  },
  industryPage: {
    title: 'Industry',
    addIndustry: 'Add Industry',
    editIndustry: 'Edit Industry',
    industryList: 'Industries',
    industryForm: {
      name: 'Name',
      title: 'Add Industry'
    },
    validations: {
      required: 'This field is required',
      successAddIndustry: 'Successfully added industry',
      successEditIndustry: 'Successfully edited industry',
      successDeleteIndustry: 'Successfully deleted industry',
      editIndustryQuestion: ' Do you want to edit this industry?',
      deleteIndustryQuestion: ' Do you want to delete this industry?'
    }
  },
  usersPage: {
    title: 'Users',
    addUser: 'Add User',
    editUser: 'Edit User',
    validations: {
      successAddUser: 'Successfully added user',
      successEditUser: 'Successfully edited user',
      successDeleteUser: 'Successfully deleted user',
      editUserQuestion: ' Do you want to edit this user?',
      deleteUserQuestion: ' Do you want to delete this user?'
    }
  },
  similarityMatrix: {
    title: 'Similarity Matrix',
    generateSimilarityMatrix: 'Generate Similarity Matrix',
    validations: {
      successGenerateMatrix: 'Successfully generated similarity matrix',
      generateMatrixQuestion: 'Do you want to generate similarity matrix?'
    }
  },
  pricingPage: {
    title: 'LeadsOwl Pricing and Plans',
    subtitle: 'Choose the plan that best suits your needs',
    price: 'Price',
    credits: 'Credits',
    freePlan: {
      title: 'Free Plan',
      features: {
        verifiedEmails: 'Verified Emails',
        verifiedPhones: 'Verified Phones',
      }
    },
    basicPlan: {
      title: 'Basic',
      features: {
        verifiedEmails: 'Verified Emails',
        verifiedPhones: 'Verified Phones',
        creditsRefund: '5 Automatic Credits Refund',
      }
    },
    premiumPlan: {
      title: 'Premium',
      features: {
        verifiedEmails: 'Verified Emails',
        verifiedPhones: 'Verified Phones',
        creditsRefund: '10 Automatic Credits Refund'
      }
    },
    customPlan: {
      title: 'Custom',
      features: {
        verifiedEmails: 'Verified Emails',
        verifiedPhones: 'Verified Phones',
        description: 'Buy as many credits as you need'
      }
    }
  },
  checkoutPage: {
    title: 'Checkout',
    pay: 'Pay',
    choosePlan: '1. Choose a plan',
    subscription: 'Subscription Plan',
    personalInformation: '2. Personal Information',
    description: 'Description',
    Basic: 'A started plan for small campaigns',
    Premium: 'Suitable for medium campaigns',
    Custom: 'Buy as many credits as you need',
    labels: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      password: 'Password',
      phone: 'Phone',
      company: 'Company',
      choosePlan: 'Choose a plan',
      selectPlan: 'Select a plan'
    },
    validations: {
      required: 'This field is required',
      successCheckout: 'Successfully checked out',
      errorCheckout: 'Error checking out',
      
    }
  }
}
