import {Strings} from '../resources/Strings'
import * as yup from 'yup'
const onlyTextRegex = /^[a-zA-Z_ ]*$/

export const EmployeesFormValidation = yup.object().shape({
  emp_first_name:
    yup
      .string()
      .trim()
      .matches(onlyTextRegex, Strings.employeePage.validations.onlyText)
      .required(Strings.employeePage.validations.required),
  emp_last_name:
    yup
      .string()
      .trim()
      .matches(onlyTextRegex, Strings.employeePage.validations.onlyText)
      .required(Strings.employeePage.validations.required),
  emp_title:
    yup
      .string()
      .trim()
      .required(Strings.employeePage.validations.required),
  emp_email:
    yup
      .string()
      .email()
      .required(Strings.employeePage.validations.required),
  emp_country:
    yup
      .string()
      .trim()
      .required(Strings.employeePage.validations.required),
  emp_phone:
    yup
      .string()
      .trim(),
  emp_linkedin:
    yup
      .string()
      .trim(),
})
