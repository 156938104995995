import {Grid, Paper} from '@mui/material'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'
import {Strings} from '../../resources/Strings'

interface CheckoutPlansFeaturesProps {
  plan: {
    name: string
    description: string
    price: number
    credits: number
    additionalCreditsPrice: number
    features: string[]
  }
}
const CheckoutPlansFeatures: React.FC<CheckoutPlansFeaturesProps> = ({ plan }) => {
  return (
    <Grid container item spacing={2}>
      <Paper
        elevation={3}
        sx={{
          position: 'relative',
          justifyItems: 'center',
          p: 2,
          width: '100%',
          backgroundColor: 'primary',
        }}
      >
        <Grid item marginBottom={2}>
          <Typography
            variant={'h5'}
            color={'primary.dark'}
          >
            {<b>{plan.name}</b>}
          </Typography>
          <Typography
            variant={'h5'}
            color={'primary.dark'}
          >
            {`${(Strings['checkoutPage'] as any)[plan.name]}`}
          </Typography>
        </Grid>
        <Divider/>
        <Grid item marginTop={2}>
          <Typography
            variant={'h3'}
          >
            {`$`}
            <b>{`${plan && plan.price}`}</b>
          </Typography>
        </Grid>
        <Grid item marginTop={2}>
          <Typography
            variant={'h6'}
          >
            {plan.credits !== 0 ? `${plan.credits} Credits` : 'Per Credit'}</Typography>
        </Grid>
        <br/>
        <Divider/>
        <Grid item marginTop={2}>
          <Typography
            variant={'h6'}
          >
            Features
          </Typography>
            <List
              dense={true}
              disablePadding={true}
            >
              {plan.features && plan.features.map((feature) => (
                  <ListItem key={feature}>
                    <ListItemText primary={feature} />
                  </ListItem>
              ))}
            </List>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default CheckoutPlansFeatures
