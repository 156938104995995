import {adminApiClient} from './axios-config'
import {IndustryType} from '../../types/IndustryType'

export const getIndustries = async (token: string) => {
  return await adminApiClient.get('/industries', {headers: {Authorization: `Bearer ${token}`}})
}

export const getIndustryById = async (iid: string, token: string) => {
  const industryId = iid ?? ''
  return await adminApiClient.get(`/industries/${industryId}`, {headers: {Authorization: `Bearer ${token}`}})
}
export const deleteIndustryById = async (iid: string, token: string) => {
  const industryId = iid ?? ''
  return await adminApiClient.delete(`/industries/${industryId}`, {headers: {Authorization: `Bearer ${token}`}})
}
export const createIndustry = async (industry: IndustryType, token: string) => {
  return await adminApiClient.post('/industries', industry, {headers: {Authorization: `Bearer ${token}`}})
}

export const updateIndustry = async (iid: string, industry: IndustryType, token: string) => {
  const industryId = iid ?? ''
  return await adminApiClient.put(`/industries/${industryId}`, industry, {headers: {Authorization: `Bearer ${token}`}})
}