import React from 'react'
import {Button, Container, Grid, Stack, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import {Strings} from '../resources/Strings'
import {Navigate, useNavigate} from 'react-router-dom'
import BaseLayout from '../layout/BaseLayout'
import {paths} from '../resources/paths'
import {useAppSelector} from '../redux/hooks'
import {useFormik} from 'formik'
import {UserType} from '../types/UserType'
import {UserFormValidation} from '../utils/UserFormValidation'
import * as authApiCalls from '../config/api/authenticationApiCalls'
import * as NotificationToasts from '../components/UI/ToastNotifications'

const SignUp = () => {
  const {isAuth} = useAppSelector(state => state.authReducer)
  const navigate = useNavigate()
  const [userValues] = React.useState<UserType>({
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    phone: '',
    company: '',
    password: '',
    credits: 0,
    plan: 'free'
  })
  const formik = useFormik<UserType>({
    initialValues: userValues,
    validationSchema: UserFormValidation,
    enableReinitialize: true,
    onSubmit: async (values: UserType, {resetForm}: { resetForm: () => void }) => {
      const response = await authApiCalls.signUp(values)
      if (response.status === 200) {
        NotificationToasts.successToast(Strings.signUpPage.validations.successRegisterAccount)
        setTimeout(() => {
          navigate(paths.dashboard)
        }, 1500)
      }
      resetForm()
      }
  })
  return isAuth
    ? <Navigate to={paths.dashboard}/>
    : (
      <Container maxWidth={'sm'}>
        <Grid
          container
          direction="column"
          alignItems={'center'}
          justifyContent={'center'}
          sx={{height: '90vh'}}
        >
          <Grid item>
            <BaseLayout>
              <Box
                component={'form'}
                onSubmit={formik.handleSubmit}
              >
                <h1>{Strings.signUpPage.title}</h1>
                <Stack spacing={1}>
                  <TextField
                    name={'first_name'}
                    onChange={formik.handleChange}
                    label={Strings.signUpPage.labels.firstName}
                    value={formik.values.first_name}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    helperText={formik.touched.first_name && formik.errors.first_name}
                  />
                  <TextField
                    name={'last_name'}
                    onChange={formik.handleChange}
                    label={Strings.signUpPage.labels.lastName}
                    value={formik.values.last_name}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    helperText={formik.touched.last_name && formik.errors.last_name}
                  />
                  <TextField
                    type={'email'}
                    name={'email'}
                    onChange={formik.handleChange}
                    label={Strings.signUpPage.email}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <TextField
                    name={'user_password'}
                    type={'password'}
                    onChange={formik.handleChange}
                    label={Strings.signUpPage.password}
                    value={formik.values.password}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                  <Button
                    type={'submit'}
                    variant={'contained'}
                    color={'secondary'}
                  >
                    {Strings.signUpPage.title}
                  </Button>
                </Stack>
              </Box>
            </BaseLayout>
          </Grid>
        </Grid>
      </Container>
    )
}

export default SignUp
