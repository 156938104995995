import React from 'react'
import {Button, Grid, Paper} from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import {useNavigate} from 'react-router-dom'
import Typography from '@mui/material/Typography'

interface PlansProps {
  name: string
  price?: number
  credits?: number
  description?: string
  features?: string[]
  buttonVariant?: string
  path: string
  dark?: boolean
}
const Plans: React.FC<PlansProps>= ({name, price, credits, features, path, dark}) => {
  const navigate = useNavigate()
  const handleButtonsNavigation = (paths: string) => {
    navigate(paths)
  }
  return (
    <Grid container item spacing={2}>
      <Paper
        elevation={3}
        sx={{
          position: 'relative',
          justifyItems: 'center',
          p: 2,
          width: 220,
          backgroundColor: dark ? 'secondary.main' : 'primary',
          height: 540
      }}
      >
        <Grid item marginBottom={2}>
          <Typography
            variant={'h5'}
            color={dark ? 'primary' : 'black'}
          >
            {<b>{name}</b>}
          </Typography>
        </Grid>
        <Divider sx={{ backgroundColor: dark ? 'primary.light' : '' }}/>
        <Grid item marginTop={2}>
          <Typography
            variant={'h3'}
            color={dark ? 'primary' : 'black'}
          >
            {'$'}
            <b>{`${price}`}</b>
          </Typography>
        </Grid>
        <Grid item marginTop={2}>
          <Typography
            variant={'h6'}
            color={dark ? 'primary' : 'black'}
          >
            {credits !== undefined ? `${credits} Credits` : 'Per Credit'}</Typography>
        </Grid>
        <Grid item marginTop={2}>
          <Button
            variant={'outlined'}
            fullWidth
            onClick={() => handleButtonsNavigation(path)}
          >
            {price === 0 ? 'Try it for Free' : 'Buy Now'}
          </Button>
        </Grid>
        <br/>
        <Divider sx={{ backgroundColor: dark ? 'primary' : '' }}/>
        <Grid item marginTop={2}>
          <Typography
            variant={'h6'}
            color={dark ? 'primary' : 'black'}
          >
            Features
          </Typography>
            <List
              dense={true}
              disablePadding={true}
            >
              {features && features.map((feature) => (
                  <ListItem key={feature}>
                    <ListItemText primary={feature} sx={{color: dark ? 'primary.light' : 'black'}}/>
                  </ListItem>
              ))}
            </List>
        </Grid>
        <Grid item justifyItems={'center'} marginTop={2}>
          <Button
            sx={{ position: 'Absolute', bottom: 0, width: '85%', marginBottom: 2 }}
            variant={'outlined'}
            onClick={() => handleButtonsNavigation(path)}
          >
            {price === 0 ? 'Try it for Free' : 'Buy Now'}
          </Button>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default Plans
