import React from 'react'
import {Button, Container, Grid, Stack, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import {Strings} from '../resources/Strings'
import {Navigate, useNavigate} from 'react-router-dom'
import BaseLayout from '../layout/BaseLayout'
import {paths} from '../resources/paths'
import {useAppDispatch, useAppSelector} from '../redux/hooks'
import {useFormik} from 'formik'
import * as NotificationToasts from '../components/UI/ToastNotifications'
import {SignInType} from '../types/SignInType'
import {authThunk} from '../redux/thunks/auth.thunk'
import {SignInFormValidation} from '../utils/SignInFormValidation'
const Login = () => {
  const {isAuth} = useAppSelector(state => state.authReducer)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [userValues] = React.useState<SignInType>({
    email: '',
    password: '',
  })
  const formik = useFormik<SignInType>({
    initialValues: userValues,
    validationSchema: SignInFormValidation,
    enableReinitialize: true,
    onSubmit: async (values: SignInType, {resetForm}: { resetForm: () => void }) => {
      if (values) {
        dispatch(authThunk(values)).then((response) => {
          if (response.payload === 401) {
            NotificationToasts.errorToast(Strings.loginPage.validations.errorLogin)
          } else {
            NotificationToasts.successToast(Strings.loginPage.validations.successLogin)
            setTimeout(() => {
              navigate(paths.dashboard)
            }, 1500)
            resetForm()
          }
        })
      }
      resetForm()
    }
  })
  return isAuth
    ? <Navigate to={paths.dashboard}/>
    : (
      <Container maxWidth={'sm'}>
        <Grid
          container
          direction="column"
          alignItems={'center'}
          justifyContent={'center'}
          sx={{height: '90vh'}}
        >
          <Grid item>
            <BaseLayout>
              <Box
                component={'form'}
                onSubmit={formik.handleSubmit}
              >
                <h1>{Strings.loginPage.title}</h1>
                <Stack spacing={1}>
                  <TextField
                    type={'email'}
                    name={'email'}
                    label={Strings.loginPage.email}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <TextField
                    type={'password'}
                    name={'password'}
                    label={Strings.loginPage.password}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                  <Button
                    type={'submit'}
                    variant={'contained'}
                    color={'secondary'}
                  >
                    {Strings.loginPage.title}
                  </Button>
                </Stack>
              </Box>
            </BaseLayout>
          </Grid>
        </Grid>
      </Container>
      )
}

export default Login
