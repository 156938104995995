import React from 'react'
import Drawer from '@mui/material/Drawer'
import {Button, Grid} from '@mui/material'

interface CartDrawerProps {
  isOpen: boolean
  handleDrawerClose: () => void
}
const CartDrawer: React.FC<CartDrawerProps> = (props) => {
  const drawerWidth = 340
  return (
    <Drawer
      PaperProps={{
        sx: {
          width: drawerWidth,
        }
      }}
      anchor={'right'}
      open={props.isOpen}
      onClose={props.handleDrawerClose}
    >
      <Grid>
        <Button onClick={props.handleDrawerClose}>
          x
        </Button>
      </Grid>
    </Drawer>
  )
  
}

export default CartDrawer