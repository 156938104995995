import {createSlice, type SliceCaseReducers} from '@reduxjs/toolkit'
import type {RejectedActionFromAsyncThunk} from '@reduxjs/toolkit/dist/matchers'
import {addOrEditEmployeeThunk} from '../thunks/addOrEditEmployeeThunk'

interface EmployeeState {
  success: boolean
  error: RejectedActionFromAsyncThunk<any> | null
  loading: boolean
}

const initialState: EmployeeState = {
  success: false,
  error: null,
  loading: false
}
export const employeesSlice = createSlice<EmployeeState, SliceCaseReducers<EmployeeState>, string>({
  name: 'employees',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrEditEmployeeThunk.pending, (state: EmployeeState) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(addOrEditEmployeeThunk.fulfilled, (state: EmployeeState) => {
        return {
          ...state,
          loading: false,
          success: true
        }
      })
      .addCase(addOrEditEmployeeThunk.rejected, (state: EmployeeState, action) => {
        return {
          ...state,
          loading: false,
          error: action.payload
        }
      })
  }
})
