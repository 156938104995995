import React, {useEffect, useMemo} from 'react'
import BaseLayout from '../../layout/BaseLayout'
import {Strings} from '../../resources/Strings'
import {NavLink} from 'react-router-dom'
import {paths} from '../../resources/paths'
import {Button, Grid} from '@mui/material'
import {MaterialReactTable, type MRT_ColumnDef} from 'material-react-table'
import DeleteButton from '../../components/buttons/DeleteButton'
import Loading from '../../components/UI/Loading'
import EditButton from '../../components/buttons/EditButton'
import * as companiesApis from '../../config/api/companiesApiCalls'
import * as NotificationToast from '../../components/UI/ToastNotifications'
import PropTypes from 'prop-types'
import {useAppSelector} from '../../redux/hooks'
import {CompaniesType} from '../../types/CompaniesType'
import BackButton from '../../components/buttons/BackButton'
import Box from '@mui/material/Box'

const CompaniesList = () => {
  const [companiesData, setCompaniesData] = React.useState<CompaniesType[]>([])
  const {accessToken, user_role} = useAppSelector(state => state.authReducer)
  const columns = useMemo<MRT_ColumnDef<CompaniesType>[]>(
    () =>
      [
        {
          id: 'comp_profile_url',
          header: 'Comp Logo',
          accessorFn: (row) => row.comp_profile_url,
          Cell: ({ row }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <img
                alt="no-logo"
                height={30}
                src={row.original.comp_profile_url}
                loading="lazy"
                style={{ borderRadius: '50%' }}
              />
            </Box>
          ),
        },
        {
          accessorKey: 'comp_name',
          header: 'Name'
        },
        {
          accessorKey: 'comp_url',
          header: 'URL'
        },
        {
          accessorKey: 'comp_country',
          header: 'Country'
        },
        {
          accessorKey: 'comp_industry',
          header: 'Industry',
        },
        {
          accessorKey: 'comp_address',
          header: 'Address'
        },
        {
          accessorKey: 'comp_post_code',
          header: 'Postal Code'
        },
        {
          accessorKey: 'comp_size',
          header: 'Size'
        },
        {
          accessorKey: 'comp_email',
          header: 'Email'
        },
        {
          accessorKey: 'comp_phone',
          header: 'Phone'
        },
        {
          accessorKey: 'comp_linkedin',
          header: 'LinkedIn'
        }
      ],
    [])
  useEffect(() => {
    if (accessToken !== null && accessToken !== undefined) {
      companiesApis.getCompanies(accessToken)
        .then((response) => {
          const data = response.data
          setCompaniesData(data)
        })
        .catch((error) => {
          NotificationToast.errorToast(error.message)
        })
    }
  }, [accessToken])
  
  const handleDeleteCompany = (id: string | undefined) => {
    if (accessToken && id !== undefined) {
      companiesApis.deleteCompany(id, accessToken)
        .then(() => {
          NotificationToast.successToast(Strings.companyPage.validations.deleteCompanySuccess)
          companiesApis.getCompanies(accessToken)
            .then((response) => {
              setCompaniesData(response.data)
            })
            .catch((error) => {
              NotificationToast.errorToast(error.message)
            })
        })
        .catch((error) => {
          NotificationToast.errorToast(error.message)
        })
    }
  }
  return (
    <BaseLayout>
      <Grid container>
        <Grid container item justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <h2>{Strings.companyPage.companyList}</h2>
          </Grid>
          <Grid item>
            <Grid container direction={'row'} spacing={2}>
              <Grid item>
                <BackButton/>
              </Grid>
              {user_role === 'admin' &&
                <Grid item>
                  <NavLink to={paths.companies.new}>
                    <Button
                      color={'secondary'}
                      variant={'contained'}
                    >
                      {Strings.companyPage.addCompany}
                    </Button>
                  </NavLink>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          {companiesData.length === 0
            ? <Loading/>
            : <Grid item xs={12}>
              <MaterialReactTable
                columns={columns}
                data={companiesData}
                enableColumnActions={user_role === 'admin'}
                enableColumnResizing
                initialState={{density: 'compact'}}
                displayColumnDefOptions={{
                  'mrt-row-numbers': {
                    size: 10
                  },
                  'mrt-row-expand': {
                    size: 8
                  },
                  'mrt-row-actions': {
                    size: 100,
                    muiTableHeadCellProps: {
                      align: 'center'
                    }
                  }
                }}
                enableRowActions={user_role === 'admin'}
                renderRowActions={(table) => {
                  const merchantId: string | undefined = table.row?.original?.comp_id
                  const editPath = `${paths.companies.basePath}/${merchantId != null ? merchantId : ''}`
                  return (
                    <>
                      <DeleteButton
                        message={Strings.companyPage.deleteCompanyQuestion}
                        onConfirm={() => { handleDeleteCompany(merchantId) }}
                      />
                      <EditButton
                        message={Strings.companyPage.editCompanyQuestion}
                        pathToRedirect={editPath}
                      />
                    </>
                  )
                }}
              />
            </Grid>
          }
        </Grid>
      </Grid>
    </BaseLayout>
  )
}

CompaniesList.propTypes = {
  merchantData: PropTypes.arrayOf(PropTypes.object),
  Cell: PropTypes.any,
  cell: PropTypes.any,
  getValue: PropTypes.any,
  row: PropTypes.any
}

export default CompaniesList
