import React from 'react'
import {ThemeProvider} from '@mui/material'
import theme from './layout/theme'
import Navigation from './components/navigation/Navigation'
import {Provider} from 'react-redux'
import {store} from './redux/store'
import {GoogleOAuthProvider} from '@react-oauth/google'
import Loading from './components/UI/Loading'

const App: React.FC = () => {
  const clientID: string = process.env.REACT_APP_CLIENT_ID ?? ''
  return (
    <Provider store={store}>
      {clientID.length > 0
        ? <GoogleOAuthProvider clientId={clientID}>
          <ThemeProvider theme={theme}>
            <Navigation/>
          </ThemeProvider>
        </GoogleOAuthProvider>
        : <Loading/>}
    </Provider>
  )
}

export default App
