import React from 'react'
import {styled} from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import type {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import {Button, Grid, Stack} from '@mui/material'
import {useAppDispatch, useAppSelector} from '../../redux/hooks'
import {Strings} from '../../resources/Strings'
import {paths} from '../../resources/paths'
import {logOut} from '../../redux/slices/authSlice'
import {NavLink, useLocation, useNavigate} from 'react-router-dom'
import {useCookies} from 'react-cookie'
import Cart from '../cart/Cart'
import NavBarWithoutDrawer from './NavBarWithoutDrawer'

const drawerWidth = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean | null
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({theme, open}) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

interface NavBarProps {
  open: boolean
  handleDrawerOpen: () => void
}

const NavBar: React.FC<NavBarProps> = ({open, handleDrawerOpen}) => {
  const navigate = useNavigate()
  const {isAuth} = useAppSelector((state) => state.authReducer)
  const location = useLocation()
  const [, , remove] = useCookies()
  const dispatch = useAppDispatch()
  const pathsWithoutNavBar = [
    paths.pricing,
    paths.checkout.basePath,
    paths.checkout.basic,
    paths.checkout.premium,
    paths.checkout.custom,
    paths.thankYou
  ]
  const hideNavBar = pathsWithoutNavBar.includes(location.pathname)
  const handleLogout = () => {
    dispatch(logOut())
    remove('accessToken')
    navigate(paths.login)
  }
  const handleLogin = () => {
    navigate(paths.login)
  }
  const handleSignUp = () => {
    navigate(paths.signUp)
  }
  return (
    <>
      {!hideNavBar ?
        <AppBar position={'fixed'} open={open} elevation={0} sx={{height: 'auto'}}>
          <Toolbar>
            <IconButton
              color="secondary"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{mr: 2, ...(open && {display: 'none'})}}
            >
              <MenuIcon/>
            </IconButton>
            <Grid
              container
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Grid item>
                <NavLink to={paths.website}
                         style={{textDecoration: 'none', display: 'flex', alignItems: 'center', color: 'black'}}>
                  <span style={{color: 'rgb(30 64 175)', fontSize: '1.25rem', lineHeight: '1.75rem'}}><b>Leads</b></span><h2 style={{color: 'black', fontSize: '1.25rem', lineHeight: '1.75rem'}}><b>Owl</b></h2>
                </NavLink>
              </Grid>
              <Grid item>
                <NavLink to={paths.pricing}
                         style={{display: 'flex', alignItems: 'center', color: 'black', fontWeight: 700, textDecoration: 'none'}}>
                  <p>Pricing</p>
                </NavLink>
              </Grid>
              {isAuth
                ? <Grid item>
                  <Cart/>
                  <Button
                    color={'secondary'}
                    variant={'contained'}
                    onClick={handleLogout}
                  >
                    {Strings.navBarMenu.logout}
                  </Button>
                </Grid>
                : <Grid item>
                  <Stack direction={'row'} spacing={1}>
                    <Button
                      color={'inherit'}
                      variant={'outlined'}
                      onClick={handleLogin}
                    >
                      <b>{Strings.navBarMenu.login}</b>
                    </Button>
                    <Button
                      variant={'contained'}
                      color={'secondary'}
                      onClick={handleSignUp}
                    >
                      {<b>{Strings.navBarMenu.signUp}</b>}
                    </Button>
                  </Stack>
                </Grid>
              }
            </Grid>
          </Toolbar>
        </AppBar>
        : <NavBarWithoutDrawer/>
      }
    </>
  )
}

export default NavBar
