export const getCookie = (name: string): string | undefined => {
  const cookie: string = `; ${document.cookie}`
  const parts: string[] = cookie.split(`${name}`)
  if (parts.length === 2) {
    const myToken = parts.pop()
    const index = myToken?.indexOf('ey')
    const substring = myToken?.substr(index as number)
    return substring
  }
}
