import React from 'react'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import StorefrontIcon from '@mui/icons-material/Storefront'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import FactoryIcon from '@mui/icons-material/Factory'
import LoginIcon from '@mui/icons-material/Login'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import GroupIcon from '@mui/icons-material/Group'
import NavigationDrawerHeader from './NavigationDrawerHeader'
import {useTheme} from '@mui/material/styles'
import {Strings} from '../../resources/Strings'
import {paths} from '../../resources/paths'
import {Link, useLocation} from 'react-router-dom'
import {useAppSelector} from '../../redux/hooks'

const drawerWidth = 240
interface NavigationDrawerProps {
  open: boolean
  handleDrawerClose: () => void
}
const NavigationDrawer: React.FC<NavigationDrawerProps> = ({open, handleDrawerClose}) => {
  const theme = useTheme()
  const {accessToken, user_role} = useAppSelector(state => state.authReducer)
  const location = useLocation()
  const pathsWithoutDrawer = [
    paths.pricing,
    paths.checkout.basePath,
    paths.checkout.basic,
    paths.checkout.premium,
    paths.checkout.custom,
    paths.thankYou
  ]
  const hideDrawer = pathsWithoutDrawer.includes(location.pathname)
  return (
    <>
    {!hideDrawer &&
      <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box'
            }
          }}
          variant="persistent"
          anchor="left"
          open={open}
          onClick={handleDrawerClose}
        >
          <NavigationDrawerHeader handleDrawerClose={handleDrawerClose} theme={theme}/>
          <Divider/>
        {accessToken !== null
          ? <>
          <List>
            <ListItem disablePadding >
              <ListItemButton component={Link} to={paths.dashboard}>
                <ListItemIcon>
                  <DashboardIcon/>
                </ListItemIcon>
                <ListItemText primary={Strings.navBarMenu.dashboard}/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton component={Link} to={paths.companies.basePath} >
                <ListItemIcon>
                  <StorefrontIcon/>
                </ListItemIcon>
                <ListItemText primary={Strings.navBarMenu.companies}/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton component={Link} to={paths.employees.basePath} >
                <ListItemIcon>
                  <PeopleAltIcon/>
                </ListItemIcon>
                <ListItemText primary={Strings.navBarMenu.employees}/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton component={Link} to={paths.industries.basePath} >
                <ListItemIcon>
                  <FactoryIcon/>
                </ListItemIcon>
                <ListItemText primary={Strings.navBarMenu.industries}/>
              </ListItemButton>
            </ListItem>
            {user_role === 'admin' &&
            <ListItem disablePadding >
              <ListItemButton component={Link} to={paths.users.basePath} >
                <ListItemIcon>
                  <GroupIcon/>
                </ListItemIcon>
                <ListItemText primary={Strings.usersPage.title}/>
              </ListItemButton>
            </ListItem>
            }
          </List>
          </>
          : <List>
            <ListItem disablePadding >
              <ListItemButton component={Link} to={paths.login}>
                <ListItemIcon>
                  <LoginIcon/>
                </ListItemIcon>
                <ListItemText primary={Strings.navBarMenu.login}/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton component={Link} to={paths.signUp}>
                <ListItemIcon>
                  <PersonAddIcon/>
                </ListItemIcon>
                <ListItemText primary={Strings.navBarMenu.signUp}/>
              </ListItemButton>
            </ListItem>
          </List>}
        </Drawer>
    }
    </>
  )
}

export default NavigationDrawer
