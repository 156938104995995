import React, {useState} from 'react'
import {BrowserRouter} from 'react-router-dom'
import NavigationDrawer from './NavigationDrawer'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import NavigationRoutes from './NavigationRoutes'
import NavBar from './NavBar'
const Navigation = () => {
  const [open, setOpen] = useState<boolean>(false)
  const handleDrawerOpen = (): void => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  return (
    <BrowserRouter>
      <Box sx={{display: 'flex'}}>
        <CssBaseline/>
        <NavBar open={open} handleDrawerOpen={handleDrawerOpen}/>
        <NavigationDrawer handleDrawerClose={handleDrawerClose} open={open} />
        <NavigationRoutes open={open} />
      </Box>
    </BrowserRouter>
  )
}
export default Navigation
