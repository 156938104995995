import React from 'react'
import {Navigate} from 'react-router-dom'
import {useAppSelector} from '../../redux/hooks'
interface PrivateRouteProps {
  children: React.ReactNode
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({children}) => {
  const {isAuth} = useAppSelector((state) => state.authReducer)
  if (!isAuth) {
    return <Navigate to="/login"/>
  }
  return <>{children}</>
}
export default PrivateRoute
