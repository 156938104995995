import {adminApiClient} from './axios-config'

export const getUsers = async (token: string) => {
  return await adminApiClient.get('/users', {headers: {Authorization: `Bearer ${token}`}})
}

export const deleteUserById = async (id: string, token: string, role: string) => {
  if (role === 'admin') {
    return await adminApiClient.delete(`/users/${id}`, {headers: {Authorization: `Bearer ${token}`}})
  }
}