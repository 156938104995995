import React from 'react'
import Plans from '../../components/products/Plans'
import {Strings} from '../../resources/Strings'
import { Grid} from '@mui/material'
import {paths} from '../../resources/paths'

const freePlanFeatures: string[] = [
  Strings.pricingPage.freePlan.features.verifiedEmails,
  Strings.pricingPage.freePlan.features.verifiedPhones
]
const basicPlanFeatures: string[] = [
  Strings.pricingPage.basicPlan.features.verifiedEmails,
  Strings.pricingPage.basicPlan.features.verifiedPhones,
  Strings.pricingPage.basicPlan.features.creditsRefund,

]
const premiumPlanFeatures: string[] = [
  Strings.pricingPage.premiumPlan.features.verifiedEmails,
  Strings.pricingPage.premiumPlan.features.verifiedPhones,
  Strings.pricingPage.premiumPlan.features.creditsRefund,
]
const customPlanFeatures: string[] = [
  Strings.pricingPage.customPlan.features.verifiedEmails,
  Strings.pricingPage.customPlan.features.verifiedPhones
]
const Pricing = () => {
  return (
    <Grid container justifyContent={'center'} spacing={6}>
      <Grid item>
        <h1>{Strings.pricingPage.title}</h1>
        <h3>{Strings.pricingPage.subtitle}</h3>
        <h3> </h3>
      </Grid>
      <Grid item container justifyContent={'center'} spacing={4}>
        <Grid item>
          <Plans
            key={Strings.pricingPage.freePlan.title}
            name={Strings.pricingPage.freePlan.title}
            price={0}
            credits={10}
            features={freePlanFeatures}
            path={paths.signUp}
          />
        </Grid>
        <Grid item>
          <Plans
            key={Strings.pricingPage.basicPlan.title}
            name={Strings.pricingPage.basicPlan.title}
            price={50}
            credits={200}
            features={basicPlanFeatures}
            path={paths.checkout.basic}
            dark={true}
          />
        </Grid>
        <Grid item>
          <Plans
            key={Strings.pricingPage.premiumPlan.title}
            name={Strings.pricingPage.premiumPlan.title}
            price={95}
            credits={500}
            features={premiumPlanFeatures}
            path={paths.checkout.premium}
          />
        </Grid>
        <Grid item>
          <Plans
            key={Strings.pricingPage.customPlan.title}
            name={Strings.pricingPage.customPlan.title}
            price={0.4}
            features={customPlanFeatures}
            path={paths.checkout.custom}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Pricing