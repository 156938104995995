import BaseLayout from '../../layout/BaseLayout'
import {Button, Grid} from '@mui/material'
import {Strings} from '../../resources/Strings'
import BackButton from '../../components/buttons/BackButton'
import {NavLink} from 'react-router-dom'
import {paths} from '../../resources/paths'
import Loading from '../../components/UI/Loading'
import {MaterialReactTable, MRT_ColumnDef} from 'material-react-table'
import DeleteButton from '../../components/buttons/DeleteButton'
import EditButton from '../../components/buttons/EditButton'
import React, {useEffect, useMemo} from 'react'
import {UserType} from '../../types/UserType'
import {useAppSelector} from '../../redux/hooks'
import * as usersApis from '../../config/api/usersApiCalls'
import * as NotificationToast from '../../components/UI/ToastNotifications'

interface UserListType {
  user_id: string
  user_first_name: string
  user_last_name: string
  user_email: string
  user_role: string
}

const UsersList = () => {
  const {accessToken, user_role} = useAppSelector(state => state.authReducer)
  const [users, setUsers] = React.useState([])
  const columns = useMemo <MRT_ColumnDef<UserListType>[]>(
    () =>
      [
        {
          accessorKey: 'user_id',
          header: 'ID'
        },
        {
          accessorKey: 'user_first_name',
          header: 'First Name'
        },
        {
          accessorKey: 'user_last_name',
          header: 'Last Name'
        },
        {
          accessorKey: 'user_email',
          header: 'Email'
        },
        {
          accessorKey: 'user_role',
          header: 'Role'
        }
      ],
    [])
  useEffect(() => {
    if (accessToken != null) {
      usersApis.getUsers(accessToken)
        .then((response) => {
          setUsers(response.data)
        })
        .catch((error) => {
          NotificationToast.errorToast(error.message)
        })
    }
  }, [accessToken])
  const handleDeleteUser = (id: string | undefined) => {
    if (id !== undefined && accessToken != null && user_role !== undefined) {
      try {
        const response = usersApis.deleteUserById(id, accessToken, user_role)
        NotificationToast.successToast(Strings.usersPage.validations.successDeleteUser)
        setUsers(users.filter((user: UserType) => user.user_id !== id))
      } catch (error: string | any) {
        NotificationToast.errorToast(error.message)
      }
      
    }
  }
  console.log(users)
  return (
    <BaseLayout>
      <Grid container>
        <Grid container item justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <h2>{Strings.usersPage.title}</h2>
          </Grid>
          <Grid item>
            <Grid container direction={'row'} spacing={2}>
              <Grid item>
                <BackButton/>
              </Grid>
              {user_role === 'admin' &&
                <Grid item>
                  <NavLink to={paths.users.new}>
                    <Button
                      color={'secondary'}
                      variant={'contained'}
                    >
                      {Strings.usersPage.addUser}
                    </Button>
                  </NavLink>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
        {users.length === 0
          ? <Loading/>
          : <Grid item xs={12}>
            <MaterialReactTable
              columns={columns}
              data={users}
              enableColumnResizing
              enableRowActions
              initialState={{ density: 'compact' }}
              renderRowActions={(table) => {
                const userId: string | undefined = table.row?.original?.user_id
                const editPath = `${paths.users.basePath}/${userId != null ? userId : ''}`
                return (
                  <>
                    <DeleteButton
                      message={Strings.usersPage.validations.deleteUserQuestion}
                      onConfirm={ () => { handleDeleteUser(userId) }}
                    />
                    <EditButton
                      message={Strings.industryPage.validations.editIndustryQuestion}
                      pathToRedirect={editPath}
                    />
                  </>
                )
              }}
            />
          </Grid>
        }
      </Grid>
    </BaseLayout>
  )
}

export default UsersList
