import React, {useEffect} from 'react'
import BaseLayout from '../../layout/BaseLayout'
import {Button, Grid, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import {Strings} from '../../resources/Strings'
import {useFormik} from 'formik'
import {EmployeesFormValidation} from '../../utils/EmployeesFormValidation'
import {useNavigate, useParams} from 'react-router-dom'
import * as employeesApis from '../../config/api/employeesApiCalls'
import {useAppSelector, useAppThunkDispatch} from '../../redux/hooks'
import {addOrEditEmployeeThunk} from '../../redux/thunks/addOrEditEmployeeThunk'
import {paths} from '../../resources/paths'
import * as NotificationToast from '../../components/UI/ToastNotifications'
import {EmployeeType} from '../../types/EmployeeType'
import BackButton from '../../components/buttons/BackButton'

const EmployeeForm = () => {
  const CREATE_EMPLOYEE_PATH = 'new'
  const {id} = useParams()
  const navigate = useNavigate()
  const {accessToken} = useAppSelector(state => state.authReducer)
  const thunkDispatch = useAppThunkDispatch()
  const [isEditing, setIsEditing] = React.useState<boolean>(false)
  const [employeeValues, setEmployeeValues] = React.useState<EmployeeType>({
    emp_id: '',
    emp_first_name: '',
    emp_last_name: '',
    emp_title: '',
    emp_email: '',
    emp_country: '',
    emp_phone: '',
    emp_linkedIn: '',
  })
  const formik = useFormik<EmployeeType>({
    initialValues: employeeValues,
    validationSchema: EmployeesFormValidation,
    enableReinitialize: true,
    onSubmit: (values, {resetForm}) => {
      setEmployeeValues(values)
      thunkDispatch(addOrEditEmployeeThunk({values, isEditing, id}))
        .then(() => {
          setTimeout(() => {
            navigate(paths.employees.basePath)
          }, 1500)
        })
        .catch((error) => {
          NotificationToast.errorToast(error.message)
        })
      resetForm()
    }
  })
  useEffect(() => {
    if (id !== CREATE_EMPLOYEE_PATH && id !== undefined && accessToken !== null) {
      setIsEditing(true)
      employeesApis.getEmployeeById(id)
        .then((response) => {
          if (response.status === 200 || response.status < 300) {
            setEmployeeValues(response.data)
          }
        })
        .catch((error) => {
          NotificationToast.errorToast(error.message)
        })
    }
  }, [id])
  return (
    <BaseLayout>
      <Box
        component={'form'}
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid container item justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
              <h2>{id !== CREATE_EMPLOYEE_PATH ? Strings.employeePage.editEmployee : Strings.employeePage.title}</h2>
            </Grid>
            <Grid item>
              <BackButton/>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name={'firstName'}
              label={Strings.employeePage.employeesForm.firstName}
              value={formik.values.emp_first_name}
              onChange={formik.handleChange}
              error={formik.touched.emp_first_name != null && Boolean(formik.errors.emp_first_name)}
              helperText={formik.touched.emp_first_name != null && formik.errors.emp_first_name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name={'lastName'}
              label={Strings.employeePage.employeesForm.lastName}
              value={formik.values.emp_last_name}
              onChange={formik.handleChange}
              error={formik.touched.emp_last_name != null && Boolean(formik.errors.emp_last_name)}
              helperText={formik.touched.emp_last_name != null && formik.errors.emp_last_name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name={'title'}
              label={Strings.employeePage.employeesForm.title}
              value={formik.values.emp_title}
              onChange={formik.handleChange}
              error={formik.touched.emp_title != null && Boolean(formik.errors.emp_title)}
              helperText={formik.touched.emp_title != null && formik.errors.emp_title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name={'email'}
              label={Strings.employeePage.employeesForm.email}
              value={formik.values.emp_email}
              onChange={formik.handleChange}
              error={formik.touched.emp_email != null && Boolean(formik.errors.emp_email)}
              helperText={formik.touched.emp_email != null && formik.errors.emp_email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name={'country'}
              label={Strings.employeePage.employeesForm.country}
              value={formik.values.emp_country}
              onChange={formik.handleChange}
              error={formik.touched.emp_country != null && Boolean(formik.errors.emp_country)}
              helperText={formik.touched.emp_country != null && formik.errors.emp_country}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name={'phone'}
              label={Strings.employeePage.employeesForm.phone}
              value={formik.values.emp_phone}
              onChange={formik.handleChange}
              error={formik.touched.emp_phone != null && Boolean(formik.errors.emp_phone)}
              helperText={formik.touched.emp_phone != null && formik.errors.emp_phone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name={'linkedIn'}
              label={Strings.employeePage.employeesForm.linkedIn}
              value={formik.values.emp_linkedIn}
              onChange={formik.handleChange}
              error={formik.touched.emp_linkedIn != null && Boolean(formik.errors.emp_linkedIn)}
              helperText={formik.touched.emp_linkedIn != null && formik.errors.emp_linkedIn}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color={'secondary'}
              type={'submit'}
              fullWidth
              variant={'contained'}
            >{id !== CREATE_EMPLOYEE_PATH ? Strings.employeePage.editEmployee : Strings.employeePage.addEmployee}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </BaseLayout>
  )
}

export default EmployeeForm
