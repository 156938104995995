import decode from 'jwt-decode'
import type {TokenFirebase} from '../types/firebase.interfaces'
export const DecodeToken = <T>(token: string): T => {
  const createDecode: T = decode(token)
  return createDecode
}

export const expirationTokenAuth = (token: string): boolean => {
  const {exp} = DecodeToken<TokenFirebase>(token)
  const currentTime = new Date().getTime() / 1000
  return exp <= Math.floor(currentTime)
}

export const getUserRole = (token: string) => {
  const {user_role} = DecodeToken<TokenFirebase>(token)
  return user_role
}

export const getUserCredits = (token: string) => {
  const {user_credits} = DecodeToken<TokenFirebase>(token)
  return user_credits
}