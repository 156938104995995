import React, {useEffect, useRef} from 'react'
import {Button} from '@mui/material'
import {Strings} from '../../resources/Strings'
import * as NotificationToast from '../UI/ToastNotifications'
import {useAppDispatch, useAppThunkDispatch} from '../../redux/hooks'
import {uploadImageThunk} from '../../redux/thunks/uploadImage.thunk'
import {inputIsPopulated} from '../../redux/slices/imagesSlice'
import {v4} from 'uuid'
interface UploadButtonProps {
  text: string
  type: string
  message: string
  canUpload: boolean
}
const UploadImageButton: React.FC<UploadButtonProps> = ({text, type, canUpload}) => {
  const canUploadImage = canUpload
  const dispatch = useAppDispatch()
  const thunkDispatch = useAppThunkDispatch()
  const [file, setFile] = React.useState(Object)
  const ref = useRef<HTMLInputElement>(null)
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('event.target.files', event.target.files)
    dispatch(inputIsPopulated(true))
    const acceptedExtension = ['jpeg', 'jpg', 'png', 'gif', 'webp']
    if (event.target.files !== null) {
      const fileExtension = event.target.files[0].name.split('.').pop()?.toLowerCase()
      if (fileExtension !== undefined && acceptedExtension.includes(fileExtension)) {
        setFile(event.target.files[0])
      } else {
        NotificationToast.errorToast(Strings.images.uploadImageError)
      }
    }
  }
  useEffect(() => {
    const imageTitle = v4()
    if (canUploadImage) {
      thunkDispatch(uploadImageThunk({file, imageTitle}))
        .then(() => {
          dispatch(inputIsPopulated(false))
          if (ref.current !== null) {
            ref.current.value = ''
          }
          NotificationToast.successToast(Strings.images.uploadImageSuccess)
        })
        .catch(() => {
          NotificationToast.errorToast(Strings.images.uploadImageError)
        })
    }
  }, [canUploadImage])
  return (
    <>
      <Button
        variant={'contained'}
        component={'label'}
      >
        {text}
        <input
          type={'file'}
          accept={type}
          onChange={handleOnChange}
          multiple
          hidden
          ref={ref}
        />
      </Button>
    </>
  )
}
export default UploadImageButton
