import Button from '@mui/material/Button'
import {Strings} from '../../resources/Strings'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
const BackButton = () => {
  return (
    <Button
      variant={'contained'}
      color={'secondary'}
      onClick={() => window.history.back()}
    >
      <ChevronLeftIcon sx={{marginLeft: -0.5}}/>
      {Strings.buttons.back}
    </Button>
  )
}
export default BackButton
