import React from 'react'
import {Button, Container, Stack} from '@mui/material'
import Typography from '@mui/material/Typography'
import {Link} from 'react-router-dom'
import {paths} from '../resources/paths'
import {Strings} from '../resources/Strings'
import Box from '@mui/material/Box'

const ErrorPage = () => {
  return (
    <Box
      sx={{
        pt: 8,
        pb: 6
      }}>
      <Container maxWidth="md">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {Strings.errorPage.error}
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          {Strings.errorPage.message}
        </Typography>
        <Stack
          sx={{pt: 4}}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Button
            rel="noreferrer"
            component={Link}
            variant={'contained'}
            color={'secondary'}
            to={paths.home}
          >
            {Strings.errorPage.backHome}
          </Button>
        </Stack>
      </Container>
    </Box>
  )
}

export default ErrorPage
