import React from 'react'
import {Paper} from '@mui/material'
import {ToastContainer} from 'react-toastify'

interface BaseLayoutProps {
  children: React.ReactNode
}

const BaseLayout: React.FC<BaseLayoutProps> = ({children}) => {
  return (
    <Paper style={{ padding: 50, margin: '0px 0' }} elevation={1}>
      {children}
      <ToastContainer/>
    </Paper>
  )
}

export default BaseLayout
