import {Button, Grid} from '@mui/material'
import {Strings} from '../../resources/Strings'
import {NavLink} from 'react-router-dom'
import {paths} from '../../resources/paths'
import Loading from '../../components/UI/Loading'
import {MaterialReactTable, MRT_ColumnDef} from 'material-react-table'
import DeleteButton from '../../components/buttons/DeleteButton'
import EditButton from '../../components/buttons/EditButton'
import BaseLayout from '../../layout/BaseLayout'
import React, {useEffect, useMemo} from 'react'
import {IndustryType} from '../../types/IndustryType'
import * as industriesApis from '../../config/api/industriesApiCalls'
import * as NotificationToast from '../../components/UI/ToastNotifications'
import {useAppSelector} from '../../redux/hooks'
import BackButton from '../../components/buttons/BackButton'
const IndustriesList = () => {
  const {accessToken, user_role} = useAppSelector(state => state.authReducer)
  const [industries, setIndustries] = React.useState<IndustryType[]>([])
  const columns = useMemo <MRT_ColumnDef<IndustryType>[]>(
    () =>
      [
        {
          accessorKey: 'ind_id',
          header: 'ID'
        },
        {
          accessorKey: 'ind_name',
          header: 'First Name'
        }
      ],
    [])
  useEffect(() => {
    if (accessToken) {
      industriesApis.getIndustries(accessToken)
        .then((response) => {
          setIndustries(response.data)
        })
        .catch((error) => {
          NotificationToast.errorToast(error.message)
        })
    }
  }, [accessToken])
  const handleDeleteIndustry = (id: string | undefined) => {
    if (id !== undefined && accessToken) {
      industriesApis.deleteIndustryById(id, accessToken)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            NotificationToast.successToast(Strings.industryPage.validations.successDeleteIndustry)
            setIndustries(industries.filter((industry) => industry.ind_id !== id))
          }
        })
        .catch((error) => {
          NotificationToast.errorToast(error.message)
        })
    }
  }
  return (
    <BaseLayout>
      <Grid container>
        <Grid container item justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <h2>{Strings.industryPage.title}</h2>
          </Grid>
          <Grid item>
            <Grid container direction={'row'} spacing={2}>
              <Grid item>
                <BackButton/>
              </Grid>
              {user_role === 'admin' &&
                <Grid item>
                  <NavLink to={paths.industries.new}>
                    <Button
                      color={'secondary'}
                      variant={'contained'}
                    >
                      {Strings.industryPage.addIndustry}
                    </Button>
                  </NavLink>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
        {industries.length === 0
          ? <Loading/>
          : <Grid item xs={12}>
            <MaterialReactTable
              columns={columns}
              data={industries}
              enableColumnResizing
              enableRowActions={user_role === 'admin'}
              initialState={{ density: 'compact' }}
              renderRowActions={(table) => {
                const industryId: string | undefined = table.row?.original?.ind_id
                const editPath = `${paths.industries.basePath}/${industryId != null ? industryId : ''}`
                return (
                  <>
                    <DeleteButton
                      message={Strings.industryPage.validations.deleteIndustryQuestion}
                      onConfirm={() => { handleDeleteIndustry(industryId) }}
                    />
                    <EditButton
                      message={Strings.industryPage.validations.editIndustryQuestion}
                      pathToRedirect={editPath}
                    />
                  </>
                )
              }}
            />
          </Grid>
        }
      </Grid>
    </BaseLayout>
  )
}

export default IndustriesList
