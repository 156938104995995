import React from 'react'
import Box from '@mui/material/Box'
import {Paper, SvgIconTypeMap} from '@mui/material'
interface infoCardProps {
  icon: React.ReactElement<SvgIconTypeMap>
  title: string
  value: number | null | undefined
}
const InfoCards: React.FC<infoCardProps> = ({icon, title, value}) => {
  return (
    <Box>
      <Paper
        elevation={2}
        style={{padding: '30px'}}
      >
        <>
        {icon}
        <h1>{value}</h1>
        <h3>{title}</h3>
        </>
      </Paper>
    </Box>
  )
}

export default InfoCards