import {adminApiClient} from './axios-config'
import {CompaniesType} from '../../types/CompaniesType'

export const getCompanies = async (token: string) => {
  return await adminApiClient.get('/companies', {headers: {Authorization: `Bearer ${token}`}})
}

export const getCompanyById = async (cid: string, token: string) => {
  const companyId = cid ?? ''
  return await adminApiClient.get(`/companies/${companyId}`, {headers: {Authorization: `Bearer ${token}`}})
}

export const createCompany = async (company: CompaniesType, token: string) => {
  return await adminApiClient.post('/companies', company, {headers: {Authorization: `Bearer ${token}`}})
}

export const updateCompany = async (cid: string | undefined, company: CompaniesType, token: string) => {
  const companyId = cid ?? ''
  return await adminApiClient.put(`/companies/${companyId}`, company, {headers: {Authorization: `Bearer ${token}`}})
}

export const deleteCompany = async (cid: string, token: string) => {
  const companyId = cid ?? ''
  return await adminApiClient.delete(`/companies/${companyId}`, {headers: {Authorization: `Bearer ${token}`}})
}
