import {adminApiClient} from './axios-config'
import {UserType} from '../../types/UserType'
import {SignInType} from '../../types/SignInType'
export const getAuth = async (token: string | undefined) => {
  return await adminApiClient.post('/auth', token)
}

export const signUp = async (user: UserType) => {
  return await adminApiClient.post('/users', {user})
}

export const signIn = async (user: SignInType) => {
  return await adminApiClient.post('/signing', user)
}