import {createAsyncThunk} from '@reduxjs/toolkit'
import * as employeesApis from '../../config/api/employeesApiCalls'
import * as NotificationToast from '../../components/UI/ToastNotifications'
import {Strings} from '../../resources/Strings'
import {EmployeeType} from '../../types/EmployeeType'
export const addOrEditEmployeeThunk = createAsyncThunk(
  'employee',
  async (
    {values, id, isEditing}: {
      values: EmployeeType
      isEditing: boolean
      id: string | undefined
    },
    {rejectWithValue}
  ) => {
    try {
      if (isEditing) {
        const FeatureEdited = await employeesApis.updateEmployee(id, values)
          .then((response) => {
            if (response.status >= 200 && response.status < 400) {
              NotificationToast.successToast(Strings.employeePage.validations.successEditEmployee)
            }
          })
          .catch((error) => {
            return rejectWithValue(error)
          })
        return FeatureEdited
      } else if (!isEditing) {
        const FeatureCreated = await employeesApis.createEmployee(values)
          .then((response) => {
            if (response.status >= 200 && response.status < 400) {
              NotificationToast.successToast(Strings.employeePage.validations.successAddEmployee)
            }
          })
          .catch((error) => {
            return rejectWithValue(error)
          })
        return FeatureCreated
      }
    } catch (error) {
      rejectWithValue(error)
    }
  })
