import * as yup from 'yup'
import {Strings} from '../resources/Strings'

export const CompanyFormValidation =
  yup.object().shape({
    comp_name:
      yup
        .string()
        .trim()
        .required(Strings.companyPage.validations.required),
      comp_industry:
      yup
        .string()
        .trim()
        .required(Strings.companyPage.validations.required),
      comp_url:
      yup
        .string()
        .trim()
        .required(Strings.companyPage.validations.required),
      comp_address:
      yup
        .string()
        .trim()
        .required(Strings.companyPage.validations.required),
      comp_post_code:
      yup
        .string()
        .trim(),
      comp_size:
      yup
        .number()
        .required(Strings.companyPage.validations.required),
    comp_country:
      yup
        .string()
        .required(Strings.companyPage.validations.required),
      comp_email:
      yup
        .string()
        .email()
        .trim(),
      comp_phone:
      yup
        .string()
        .trim(),
      comp_linkedin:
      yup
        .string()
        .trim(),
      comp_profile_url:
        yup
          .string()
          .trim()
  })
