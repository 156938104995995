import React from 'react'
import {Grid} from '@mui/material'
import InfoCards from '../components/dashboard/InfoCards'
import SavingsIcon from '@mui/icons-material/Savings';
import PeopleIcon from '@mui/icons-material/People';
import {useAppSelector} from '../redux/hooks'
import {Strings} from '../resources/Strings'
import Typography from '@mui/material/Typography'

const Dashboard = () => {
  const {user_credits} = useAppSelector(state => state.authReducer)
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography
          variant={'h3'}
          component={'h3'}
        >
          {Strings.dashboardPage.title}
        </Typography>
        <span>{Strings.dashboardPage.subtitle}</span>
      </Grid>
      <Grid item xs={3}>
        <InfoCards icon={<SavingsIcon/>} title={Strings.dashboardPage.credits} value={user_credits}/>
      </Grid>
      <Grid item xs={3}>
        <InfoCards icon={<PeopleIcon/>} title={'Leads'} value={1000}/>
      </Grid>
    </Grid>
  )
}

export default Dashboard
