import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import React from 'react'
import DialogActions from '@mui/material/DialogActions'
import {Strings} from '../../resources/Strings'
import {Button} from '@mui/material'
import {useNavigate} from 'react-router-dom'

interface EditButtonProps {
  message: string
  pathToRedirect: string | undefined
}

const EditButton: React.FC<EditButtonProps> = ({message, pathToRedirect}) => {
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleRedirect = () => {
    setOpen(false)
    pathToRedirect != null &&
    navigate(pathToRedirect)
  }
  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        color={'secondary'}
      >
        <EditIcon/>
      </IconButton>
      <Dialog
        onClose={handleClose}
        open={open}
      >
        <DialogTitle>{Strings.app.edit}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={'outlined'} onClick={handleClose}>{Strings.buttons.no}</Button>
          <Button variant={'contained'} color={'secondary'} onClick={handleRedirect}>{Strings.buttons.yes}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditButton
