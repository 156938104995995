import * as yup from 'yup'
import {Strings} from '../resources/Strings'

export const UserFormValidation =
  yup.object().shape({
    first_name:
      yup
        .string()
        .required(Strings.industryPage.validations.required),
    
    last_name:
      yup
        .string()
        .required(Strings.industryPage.validations.required),
    email:
      yup
        .string()
        .email(Strings.signUpPage.validations.email)
        .required(Strings.signUpPage.validations.required),
    password:
      yup
        .string()
        .trim()
        .required(Strings.signUpPage.validations.required),
    company:
      yup
        .string()
        .required(Strings.signUpPage.validations.required),
    phone:
      yup
        .number()
        .required(Strings.signUpPage.validations.required),
  })
