import {createAsyncThunk} from '@reduxjs/toolkit'
import * as PaymentApiCalls from '../../config/api/paymentApiCalls'
export const addOrderThunk = createAsyncThunk(
  'order',
  async (
    {selectedPlan}: { selectedPlan: string},
    {rejectWithValue}
  ) => {
    let planValue = 0
    const planName = selectedPlan.toLowerCase()
    if (planName === 'basic') {
      planValue = 50.00;
    } else if (planName === 'premium') {
      planValue = 95.00;
    } else if (planName === 'custom') {
      planValue = 0.4;
    }
    
    try {
      const paymentUrl = await PaymentApiCalls.createOrder(planValue)
      paymentUrl.data && window.open(paymentUrl.data, '_blank')
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
