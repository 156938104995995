import {createSlice} from '@reduxjs/toolkit'
import type {RejectedActionFromAsyncThunk} from '@reduxjs/toolkit/dist/matchers'
import {uploadImageThunk} from '../thunks/uploadImage.thunk'
interface ImageState {
  success: boolean
  error: RejectedActionFromAsyncThunk<any> | null
  loading: boolean
  imageUrl: string
  hasInputChanged?: boolean
}
const initialState: ImageState = {
  success: false,
  error: null,
  loading: false,
  imageUrl: '',
  hasInputChanged: false
}
export const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    inputIsPopulated: (state: ImageState, action) => {
      state.hasInputChanged = action.payload
    },
    resetImageUrl: (state: ImageState, action) => {
      state.imageUrl = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(uploadImageThunk.pending, (state: ImageState) => {
      return ({
        ...state,
        loading: true
      })
    })
    builder.addCase(uploadImageThunk.fulfilled, (state: ImageState, action) => {
      return ({
        ...state,
        loading: false,
        success: true,
        hasInputChanged: true,
        imageUrl: action.payload
      })
    })
    builder.addCase(uploadImageThunk.rejected, (state: ImageState, action) => {
      return ({
        ...state,
        error: action.payload
      })
    })
  }
})

export const {inputIsPopulated, resetImageUrl} = imagesSlice.actions
