import React, {useEffect} from 'react'
import {styled} from '@mui/material/styles'
import {Route, Routes, useLocation} from 'react-router-dom'
import {paths} from '../../resources/paths'
import Home from '../../pages/Home'
import ErrorPage from '../../pages/ErrorPage'
import CompaniesList from '../../pages/companies/CompaniesList'
import Dashboard from '../../pages/Dashboard'
import Login from '../../pages/Login'
import SignUp from '../../pages/SignUp'
import PrivateRoute from './PrivateRoute'
import {useCookies} from 'react-cookie'
import {useAppSelector} from '../../redux/hooks'
import CompanyForm from '../../pages/companies/CompanyForm'
import EmployeesList from '../../pages/employees/EmployeesList'
import EmployeeForm from '../../pages/employees/EmployeeForm'
import IndustriesList from '../../pages/industries/IndustriesList'
import IndustryForm from '../../pages/industries/IndustryForm'
import UsersList from '../../pages/users/UsersList'
import Pricing from '../../pages/sales/Pricing'
import Checkout from '../../pages/sales/Checkout'
import {Container} from '@mui/material'
import ThankYou from '../../pages/sales/ThankYou'

const drawerWidth = 240

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
  open?: boolean | null
}>(({theme, open}) => ({
  width: '100%',
  padding: theme.spacing(12, 3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${drawerWidth}px`,
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  ...(open && {
    padding: theme.spacing(12, 3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  })
}))

interface Props {
  open: boolean | null
}

const NavigationRoutes: React.FC<Props> = ({open}) => {
  const [, setCookie, remove] = useCookies()
  const {
    accessToken,
    isExpired,
    user_role
  } = useAppSelector(state => state.authReducer)
  const location = useLocation()
  
  const pathsWithoutMain = [
    paths.pricing,
    paths.checkout.basePath,
    paths.checkout.basic,
    paths.checkout.premium,
    paths.checkout.custom,
    paths.thankYou
  ]
  const hideMain = pathsWithoutMain.includes(location.pathname)
  
  useEffect(() => {
    if (accessToken != null) {
      setCookie('accessToken', accessToken, {path: '/'})
    }
  }, [accessToken])
  useEffect(() => {
    if (isExpired !== null && isExpired) {
      remove('accessToken')
    }
  }, [isExpired])
  return (
    <>
      {!hideMain
        ?
        <Main open={open}>
          <Routes>
            <Route path={paths.home} element={<Home/>}/>
            <Route path={paths.dashboard} element={<PrivateRoute> <Dashboard/> </PrivateRoute>}/>
            <Route path={`${paths.companies.basePath}/:id`} element={<PrivateRoute> <CompanyForm/> </PrivateRoute>}/>
            <Route path={`${paths.employees.basePath}/:id`} element={<PrivateRoute> <EmployeeForm/> </PrivateRoute>}/>
            <Route path={`${paths.industries.basePath}/:id`} element={<PrivateRoute> <IndustryForm/> </PrivateRoute>}/>
            <Route path={paths.companies.basePath} element={<PrivateRoute> <CompaniesList/> </PrivateRoute>}/>
            <Route path={paths.employees.basePath} element={<PrivateRoute> <EmployeesList/> </PrivateRoute>}/>
            <Route path={paths.industries.basePath} element={<PrivateRoute> <IndustriesList/> </PrivateRoute>}/>
            <Route path={paths.users.basePath} element={user_role === 'admin' ? <PrivateRoute> <UsersList/> </PrivateRoute> : <ErrorPage/>}/>
            <Route path={paths.login} element={<Login/>}/>
            <Route path={paths.signUp} element={<SignUp/>}/>
            <Route path={'*'} element={<ErrorPage/>}/>
          </Routes>
        </Main>
        :
        <Container
          sx={{
            marginTop: 12,
            width: '100%'
          }}
        >
          <Routes>
            <Route path={paths.pricing} element={<Pricing/>}/>
            <Route path={paths.thankYou} element={<ThankYou/>}/>
            <Route path={`${paths.checkout.basePath}/:id`} element={<Checkout/>}/>
          </Routes>
        </Container>
      }
    </>
  )
}

export default NavigationRoutes
