import * as yup from 'yup'
import {Strings} from '../resources/Strings'

export const CheckoutFormValidation =
  yup.object().shape({
    first_name:
      yup
        .string()
        .required(Strings.checkoutPage.validations.required),
    last_name:
      yup
        .string()
        .required(Strings.checkoutPage.validations.required),
    email:
      yup
        .string()
        .email()
        .required(Strings.checkoutPage.validations.required),
    password:
      yup
        .string()
        .required(Strings.checkoutPage.validations.required),
    phone:
      yup
        .string()
        .required(Strings.checkoutPage.validations.required),
    company:
      yup
        .string()
        .required(Strings.checkoutPage.validations.required),
  })
