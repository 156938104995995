import {createAsyncThunk} from '@reduxjs/toolkit'
import {storage} from '../../config/firebaseConfig'
import * as imageStorage from 'firebase/storage'

export const uploadImageThunk = createAsyncThunk(
  'firebase/storage',
  async (
    {file, imageTitle}: { file: File | Blob, imageTitle: string},
    {rejectWithValue}
  ) => {
    try {
      const storageRef = imageStorage.ref(storage, `companiesProfilePictures/${imageTitle}`)
      const image = await imageStorage.uploadBytes(storageRef, file)
        .then(async (snapshot) => {
          return await imageStorage.getDownloadURL(snapshot.ref)
        })
      return image
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
