import {createAsyncThunk} from '@reduxjs/toolkit'
import * as companyApis from '../../config/api/companiesApiCalls'
import * as NotificationToast from '../../components/UI/ToastNotifications'
import {Strings} from '../../resources/Strings'
import {CompaniesType} from '../../types/CompaniesType'

export const addOrEditCompanyThunk = createAsyncThunk(
  'company',
  async (
    {values, isEditing, id, imageUrl, hasInputChanged, accessToken}: {
      values: CompaniesType
      isEditing: boolean
      id?: string | undefined
      imageUrl: string
      hasInputChanged: boolean | undefined,
      accessToken: string
    },
    {rejectWithValue}
  ) => {
    console.log('VALUES', values)
    try {
      const companyToUpload: CompaniesType = {
        ...values,
        comp_profile_url:
          (isEditing && hasInputChanged !== null && hasInputChanged !== false)
            ? imageUrl
            : (isEditing && hasInputChanged !== null && hasInputChanged === false)
              ? values.comp_profile_url
              : (!isEditing && hasInputChanged !== null && hasInputChanged === true)
                ? imageUrl
                : values.comp_profile_url
      }
      if (isEditing && accessToken) {
        const companyEdited = await companyApis.updateCompany(id, companyToUpload, accessToken)
          .then(() => {
            NotificationToast.successToast(Strings.companyPage.validations.successEditCompany)
          })
          .catch((error) => {
            return rejectWithValue(error)
          })
        return companyEdited
      } else {
        const companyCreated = await companyApis.createCompany(companyToUpload, accessToken)
          .then((response) => {
            console.log(response)
            if (response.status >= 200 && response.status < 300) {
              NotificationToast.successToast(Strings.companyPage.validations.successAddCompany)
            }
          })
          .catch((error) => {
            return rejectWithValue(error)
          })
        return companyCreated
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
