import React from 'react'
import {CircularProgress} from '@mui/material'

const Loading = () => {
  return (
    <center style={{paddingTop: '100px', margin: 'auto' }}>
      <CircularProgress
        color={'secondary'}
      />
    </center>
  )
}

export default Loading
