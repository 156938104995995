import {createSlice} from '@reduxjs/toolkit'
import {addOrderThunk} from '../thunks/addOrder.thunk'

interface CartState {
  items: string[]
  loading?: boolean
  error?: string | null
  
}

const initialState: CartState = {
  items: []
}
export const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState,
  reducers: {
    addItem: (state: CartState, action) => {
      state.items.push(action.payload)
    }
  },
  extraReducers(builder) {
    builder.addCase(addOrderThunk.pending, (state: CartState) => {
      return {
        ...state,
        loading: true
      }
    })
    builder.addCase(addOrderThunk.fulfilled, (state: CartState) => {
      return {
        ...state,
        loading: false
      }
    })
    builder.addCase(addOrderThunk.rejected, (state: CartState) => {
      return {
        ...state,
        loading: false,
        error: 'error'
      }
    })
  }
})

export const { addItem } = cartSlice.actions
