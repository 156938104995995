import React from 'react'
import {Chip} from '@mui/material'
import BaseLayout from '../layout/BaseLayout'
const Home = () => {
  return (
     <BaseLayout>
       <Chip label={'Home'}/>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa fuga fugiat inventore
          libero nostrum officia soluta. Adipisci, alias at atque autem ea error facilis id illo magnam
          nesciunt ratione veniam? A, ab accusamus asperiores autem blanditiis culpa cum delectus deserunt
          dicta dignissimos dolor doloribus eaque harum iusto labore modi molestiae nemo neque nihil
          obcaecati placeat quisquam, ratione reiciendis rem similique? Facere fugit, ipsa! Architecto
          aut et explicabo labore quia unde, voluptatem. Ab accusamus adipisci beatae cupiditate deserunt
          eaque eligendi, facilis maiores minus odit praesentium quia repellendus reprehenderit tempore
        </p>
    </BaseLayout>
  )
}

export default Home
