import React, {useState, useEffect} from 'react'
import * as usersApiCalls from '../../config/api/authenticationApiCalls'
import {UserType} from '../../types/UserType'
import * as NotificationToast from '../../components/UI/ToastNotifications'
import {useNavigate} from 'react-router-dom'
import {paths} from '../../resources/paths'

const userCredits = {
  basic: 200,
  premium: 500,
  custom: 0
}

const ThankYou = () => {
  const navigate = useNavigate()
  const [userData, setUserData] = useState<UserType>({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    company: '',
    password: '',
    plan: '',
    credits: 0,
  })
  useEffect(() => {
    if (localStorage.getItem('formData')){
      const data = JSON.parse(localStorage.getItem('formData')!)
      setUserData({
        ...data,
        credits: data.selectedPlan === 'basic' ? userCredits.basic : data.selectedPlan === 'premium' ? userCredits.premium : data.credits,
      })
    }
  }, [])
  
  useEffect(() => {
    if (userData.first_name !== '') {
      usersApiCalls.signUp(userData as UserType)
        .then((response) => {
          if(response.status >= 200 && response.status < 300) {
            NotificationToast.successToast('Account created successfully!')
            localStorage.removeItem('formData')
          }
          setTimeout(() => navigate(paths.login), 2500)
        })
        .catch((e) => NotificationToast.errorToast(e.message))
    }
  }, [userData])
  return (
    <div className="container">
      <h1>Thank you for your purchase!</h1>
      <h3>Redirecting to login page...</h3>
    </div>
  )
}

export default ThankYou
