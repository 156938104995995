import React from 'react'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import IconButton from '@mui/material/IconButton'
import {useAppSelector} from '../../redux/hooks'
import CartDrawer from './CartDrawer'
const Cart = () => {
  const {items} = useAppSelector((state) => state.cartReducer)
  const {user_credits} = useAppSelector((state) => state.authReducer)
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false)
  const handleDrawerClose = () => {
    setOpenDrawer(false)
  }
  return (
    <>
      <IconButton
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <ShoppingCartIcon
        color={'secondary'}
        />
        {user_credits}
      </IconButton>
      <CartDrawer isOpen={openDrawer} handleDrawerClose={handleDrawerClose}/>
    </>
  )
}

export default Cart
