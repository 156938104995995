import {createTheme} from '@mui/material/styles'
import type {PaletteColor} from '@mui/material'
import {red} from '@mui/material/colors'
import '../customFonts.css'
import '@fontsource/roboto'
import '@fontsource-variable/nunito-sans'

declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      primary: PaletteColor
      secondary: PaletteColor
    }
  }
  
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string
    }
  }
}
const palette = {
  primary: {main: '#FFFFFF', light: '#FFFFFF', dark: '#444444'},
  secondary: {main: 'rgb(30 64 175)'},
  error: {main: red[500]},
  gradient: {sogoGradient: 'linear-gradient(130deg, #00B8FF 16.56%, #09F06F 100%)'}
}

export type Theme = typeof theme

const theme = createTheme({
  palette,
  typography: {
    fontFamily: ["Nunito Sans Variable", "sans-serif"].join(','),
    // fontWeightMedium: 400,
    fontWeightBold: 800
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          textTransform: 'none',
          fontWeight: 800,
          paddingTop: '.625rem',
          paddingBottom: '.625rem',
          fontSize: '.875rem',
          lineHeight: '1.25rem',
        },
        contained: {
          backgroundColor: palette.secondary.main,
          borderColor: palette.primary.light,
          color: palette.primary.light,
          
        },
        outlined: {
          backgroundColor: palette.primary.light,
          borderColor: palette.secondary.main,
          color: palette.secondary.main,
          ':hover': {
            backgroundColor: palette.secondary.main,
            color: palette.primary.light,
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: palette.primary.dark,
            },
            '&.Mui-focused fieldset legend': {
              color: palette.primary.dark,
            }
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: palette.primary.dark,
          '&.Mui-focused': {
            color: palette.primary.dark,
          }
        },
      }
    },
    /*MuiMenuItem: {
      styleOverrides: {
        root: {
          color: palette.primary.dark,
          '&.Mui-selected': {
            color: palette.primary.dark,
          },
          '&.Mui-focused': {
            color: palette.primary.dark,
          },
          '&.Mui-selected.Mui-focused': {
            color: palette.primary.dark,
          }
        },
      }
    }*/
  }
})

export default theme
