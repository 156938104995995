import * as yup from 'yup'
import {Strings} from '../resources/Strings'

export const SignInFormValidation =
  yup.object().shape({
    email:
      yup
        .string()
        .email(Strings.signUpPage.validations.email)
        .required(Strings.signUpPage.validations.required),
    password:
      yup
        .string()
        .trim()
        .required(Strings.signUpPage.validations.required),
  })
