import React, {useEffect, useMemo} from 'react'
import BaseLayout from '../../layout/BaseLayout'
import {Button, Grid} from '@mui/material'
import DeleteButton from '../../components/buttons/DeleteButton'
import EditButton from '../../components/buttons/EditButton'
import {MaterialReactTable, type MRT_ColumnDef} from 'material-react-table'
import {Strings} from '../../resources/Strings'
import {NavLink} from 'react-router-dom'
import {paths} from '../../resources/paths'
import {useAppSelector} from '../../redux/hooks'
import * as employeesApis from '../../config/api/employeesApiCalls'
import * as NotificationToast from '../../components/UI/ToastNotifications'
import Loading from '../../components/UI/Loading'
import {EmployeeType} from '../../types/EmployeeType'
import BackButton from '../../components/buttons/BackButton'
const EmployeesList = () => {
  const [employees, setEmployees] = React.useState<EmployeeType[]>([])
  const {accessToken} = useAppSelector(state => state.authReducer)
  const columns = useMemo <MRT_ColumnDef<EmployeeType>[]>(
    () =>
      [
        {
          accessorKey: 'emp_id',
          header: 'ID'
        },
        {
          accessorKey: 'emp_first_name',
          header: 'First Name'
        },
        {
          accessorKey: 'emp_last_name',
          header: 'Last Name'
        },
        {
          accessorKey: 'emp_title',
          header: 'Position'
        },
        {
          accessorKey: 'emp_email',
          header: 'Email'
        },
        {
          accessorKey: 'emp_country',
          header: 'Country'
        },
        {
          accessorKey: 'emp_phone',
          header: 'Phone'
        },
        {
          accessorKey: 'emp_linkedIn',
          header: 'LinkedIn'
        }
      ],
    [])
  useEffect(() => {
    if (accessToken != null) {
      employeesApis.getEmployees()
        .then((response) => {
          setEmployees(response.data)
        })
        .catch((error) => {
          NotificationToast.errorToast(error.message)
        })
    }
  }, [])
  const handleDeleteEmployee = (id: string | undefined) => {
    if (id !== undefined && accessToken != null) {
      employeesApis.deleteEmployee(id)
        .then((response) => {
          NotificationToast.successToast(Strings.employeePage.validations.successDeleteEmployee)
          setEmployees(employees.filter((employee) => employee.emp_id !== id))
        })
        .catch((error) => {
          NotificationToast.errorToast(error.message)
        })
    }
  }
  return (
    <BaseLayout>
      <Grid container>
        <Grid container item justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <h2>{Strings.employeePage.title}</h2>
          </Grid>
          <Grid item>
            <Grid container direction={'row'} spacing={2}>
              <Grid item>
                <BackButton/>
              </Grid>
              <Grid item>
                <NavLink to={paths.employees.new}>
                  <Button
                    color={'secondary'}
                    variant={'contained'}
                  >
                    {Strings.employeePage.addEmployee}
                  </Button>
                </NavLink>
              </Grid>
          </Grid>
        </Grid>
        </Grid>
        {employees.length === 0
          ? <Loading/>
          : <Grid item xs={12}>
            <MaterialReactTable
              columns={columns}
              data={employees}
              enableColumnResizing
              enableRowActions
              initialState={{ density: 'compact' }}
              renderRowActions={(table) => {
                const employeeId: string | undefined = table.row?.original?.emp_id
                const editPath = `${paths.employees.basePath}/${employeeId != null ? employeeId : ''}`
                return (
                  <>
                    <DeleteButton
                      message={Strings.employeePage.deleteEmployeeQuestion}
                      onConfirm={() => { handleDeleteEmployee(employeeId) }}
                    />
                    <EditButton
                      message={Strings.employeePage.editEmployeeQuestion}
                      pathToRedirect={editPath}
                    />
                  </>
                )
              }}
            />
        </Grid>
        }
      </Grid>
    </BaseLayout>
  )
}

export default EmployeesList
