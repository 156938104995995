import {createAsyncThunk} from '@reduxjs/toolkit'
import * as AuthApiCalls from '../../config/api/authenticationApiCalls'
import {SignInType} from '../../types/SignInType'

export const authThunk = createAsyncThunk(
  'auth',
  async (
    values: SignInType,
    {rejectWithValue}
) => {
    try {
      const auth = await AuthApiCalls.signIn(values)
      console.log('auth', auth)
      return auth
    } catch (error) {
      return rejectWithValue(error)
    }
  })
