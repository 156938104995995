import {adminApiClient} from './axios-config'
import {EmployeeType} from '../../types/EmployeeType'

export const getEmployees = async () => {
  return await adminApiClient.get('/employees')
}

export const getEmployeeById = async (eid: string) => {
  const employeeId = eid ?? ''
  return await adminApiClient.get(`/employees/${employeeId}`)
}

export const createEmployee = async (employee: EmployeeType) => {
  return await adminApiClient.post('/employees', employee)
}

export const updateEmployee = async (eid: string | undefined, employee: EmployeeType) => {
  const employeeId = eid ?? ''
  return await adminApiClient.put(`/employees/${employeeId}`, employee)
}

export const deleteEmployee = async (eid: string) => {
  const employeeId = eid ?? ''
  return await adminApiClient.delete(`/employees/${employeeId}`)
}
