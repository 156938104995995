import React from 'react'
import {AppBar, Grid} from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import {NavLink} from 'react-router-dom'
import {paths} from '../../resources/paths'
import cyan from '@mui/material/colors/cyan'
const NavBarWithoutDrawer = () => {
  return (
    <AppBar
      position={'fixed'}
      elevation={0}
      sx={{
        backgroundColor: 'primary',
        color: 'black'
      }}
    >
      <Toolbar>
        <Grid
          container
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid item>
            <NavLink to={paths.website}
                     style={{textDecoration: 'none', display: 'flex', alignItems: 'center', color: 'black'}}>
              <span style={{color: 'rgb(30 64 175)', fontSize: '26px'}}><b>Leads</b></span><h2 style={{color: 'black'}}>Owl</h2>
            </NavLink>
          </Grid>
          
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default NavBarWithoutDrawer
