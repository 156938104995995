import React, {useEffect} from 'react'
import {IndustryType} from '../../types/IndustryType'
import BaseLayout from '../../layout/BaseLayout'
import {useFormik} from 'formik'
import * as industryApis from '../../config/api/industriesApiCalls'
import {Button, Grid, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import {Strings} from '../../resources/Strings'
import {useNavigate, useParams} from 'react-router-dom'
import {IndustryFormValidation} from '../../utils/IndustryFormValidation'
import {paths} from '../../resources/paths'
import * as NotificationToasts from '../../components/UI/ToastNotifications'
import * as NotificationToast from '../../components/UI/ToastNotifications'
import {useAppSelector} from '../../redux/hooks'
import BackButton from '../../components/buttons/BackButton'

const IndustryForm = () => {
  const CREATE_INDUSTRY_PATH = 'new'
  const {id} = useParams()
  const {accessToken} = useAppSelector(state => state.authReducer)
  const navigate = useNavigate()
  const [isEditing, setIsEditing] = React.useState<boolean>(false)
  const [industryValues, setIndustryValues] = React.useState<IndustryType>({
    ind_name: ''
  })
  const formik = useFormik<IndustryType>({
    initialValues: industryValues,
    validationSchema: IndustryFormValidation,
    enableReinitialize: true,
    onSubmit: (values: IndustryType, {resetForm}: { resetForm: () => void }) => {
      if (isEditing) {
        if (id !== undefined && accessToken) {
          industryApis.updateIndustry(id, values, accessToken)
            .then((response) => {
              if (response.status >= 200 && response.status < 300) {
                NotificationToasts.successToast(Strings.industryPage.validations.successEditIndustry)
                setTimeout(() => {
                  navigate(paths.industries.basePath)
                }, 1500)
              }
            })
            .catch((error) => {
              NotificationToasts.errorToast(error.message)
            })
        }
      } else {
        if (accessToken) {
          industryApis.createIndustry(values, accessToken)
            .then((response) => {
              console.log(response)
              if (response.status >= 200 && response.status < 300) {
                NotificationToasts.successToast(Strings.industryPage.validations.successAddIndustry)
                setTimeout(() => {
                  navigate(paths.industries.basePath)
                }, 1500)
              }
            })
            .catch((error) => {
              NotificationToasts.errorToast(error.message)
            })
        }
      }
      resetForm()
    }
  })
  useEffect(() => {
    if (id !== CREATE_INDUSTRY_PATH && id !== undefined && accessToken) {
      setIsEditing(true)
      industryApis.getIndustryById(id, accessToken)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            setIndustryValues({
              ...industryValues,
              ind_name: response.data.ind_name
            })
          }
        })
        .catch((error) => {
          NotificationToast.errorToast(error.message)
        })
    }
  }, [id])
  return (
    <BaseLayout>
      <Box
        component={'form'}
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid container item justifyContent={'space-between'} alignItems={'center'}>
            <Grid item >
              <h2>{id !== CREATE_INDUSTRY_PATH ? Strings.industryPage.editIndustry : Strings.industryPage.industryForm.title}</h2>
            </Grid>
            <Grid item>
            <BackButton/>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name={'ind_name'}
              label={Strings.industryPage.industryForm.name}
              value={formik.values.ind_name}
              onChange={formik.handleChange}
              error={formik.touched.ind_name != null && Boolean(formik.errors.ind_name)}
              helperText={formik.touched.ind_name != null && formik.errors.ind_name}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color={'secondary'}
              fullWidth
              variant={'contained'}
              type={'submit'}
            >{id !== CREATE_INDUSTRY_PATH ? Strings.industryPage.editIndustry : Strings.industryPage.addIndustry}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </BaseLayout>
  );
}

export default IndustryForm
