import {Strings} from './Strings'

export const plansListWithFeatures = [
  {
    name: 'Basic',
    price: 50,
    credits: 200,
    features: [
      Strings.pricingPage.basicPlan.features.verifiedEmails,
      Strings.pricingPage.basicPlan.features.verifiedPhones,
      Strings.pricingPage.basicPlan.features.creditsRefund
    ],
    additionalCreditsPrice: 0.20,
    description: 'Basic plan description'
  },
  {
    name: 'Premium',
    price: 95,
    credits: 500,
    features: [
      Strings.pricingPage.premiumPlan.features.verifiedEmails,
      Strings.pricingPage.premiumPlan.features.verifiedPhones,
      Strings.pricingPage.premiumPlan.features.creditsRefund
    ],
    additionalCreditsPrice: 0.15,
    description: 'Premium plan description'
  },
  {
    name: 'Custom',
    price: 0.4,
    credits: 0,
    features: [
      Strings.pricingPage.customPlan.features.verifiedEmails,
      Strings.pricingPage.customPlan.features.verifiedPhones,
      Strings.pricingPage.customPlan.features.description
    ],
    additionalCreditsPrice: 0,
    description: 'Custom plan description'
  }
]
