 import React, {useEffect} from 'react'
import {Button, Grid, MenuItem, Paper, TextField} from '@mui/material'
import Typography from '@mui/material/Typography'
import {Strings} from '../../resources/Strings'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import {useFormik} from 'formik'
import {useParams} from 'react-router-dom'
import {CheckoutFormValidation} from '../../utils/CheckoutFormValidation';
 import {useAppDispatch} from '../../redux/hooks'
 import {addOrderThunk} from '../../redux/thunks/addOrder.thunk'
interface CheckoutPlansProps {
  changePlan: (plan: string) => void
}
interface plans {
  id: number
  name: string
}

const CheckoutPlans: React.FC<CheckoutPlansProps> = ({changePlan}) => {
  const id = useParams<{ id: string }>()
  const dispatch = useAppDispatch()
  const [actualPlans] = React.useState<plans[]>([
      {id: 1, name: 'Basic'},
      {id: 2, name: 'Premium'},
      {id: 3, name: 'Custom'}
    ]
  )
  const [planSelected, setPlanSelected] = React.useState<string>('')
  const [values, setValues] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    company: '',
    password: '',
    selectedPlan: ''
  })
  const formik = useFormik({
    initialValues: values,
    validationSchema: CheckoutFormValidation,
    onSubmit: async (values, {resetForm}: { resetForm: () => void }) => {
      const formValues = {
        ...values,
        selectedPlan: planSelected.toLowerCase()
      }
      setValues(formValues)
      dispatch(addOrderThunk(formValues))
      localStorage.setItem('formData', JSON.stringify(formValues))
      resetForm()
    }
  })
  useEffect(() => {
    const selectedPlan = actualPlans.find(plan => plan.name.toLowerCase() === id.id)
    if (selectedPlan) {
      setPlanSelected(selectedPlan.name)
      changePlan(selectedPlan.name)
    }
  }, [])
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlanSelected(event.target.value)
    changePlan(event.target.value)
  }
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 4,
      }}
    >
      <Box
        component={'form'}
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={3} direction={'column'}>
          <Grid item>
            <Typography>{Strings.checkoutPage.choosePlan}</Typography>
          </Grid>
          <Divider/>
          <Grid item>
            <Box maxWidth={'200px'}>
              <TextField
                select
                fullWidth
                name={'checkout_plan'}
                label={Strings.checkoutPage.labels.selectPlan}
                onChange={handleChange}
                value={planSelected}
              >
                {actualPlans && actualPlans.map((plan) => (
                  <MenuItem key={plan.id} value={plan.name}>
                    {plan.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Typography>{Strings.checkoutPage.description}</Typography>
          </Grid>
          <Grid item>
            <Typography>{Strings.checkoutPage.personalInformation}</Typography>
            <Divider/>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TextField
                name={'first_name'}
                value={formik.values.first_name}
                onChange={formik.handleChange}
                label={Strings.checkoutPage.labels.firstName}
                error={formik.touched.first_name != null && Boolean(formik.errors.first_name)}
                helperText={formik.touched.first_name != null && formik.errors.first_name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={'last_name'}
                value={formik.values.last_name}
                label={Strings.checkoutPage.labels.lastName}
                onChange={formik.handleChange}
                error={formik.touched.last_name != null && Boolean(formik.errors.last_name)}
                helperText={formik.touched.last_name != null && formik.errors.last_name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type={'email'}
                name={'email'}
                value={formik.values.email}
                onChange={formik.handleChange}
                label={Strings.checkoutPage.labels.email}
                error={formik.touched.email != null && Boolean(formik.errors.email)}
                helperText={formik.touched.email != null && formik.errors.email}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={'phone'}
                value={formik.values.phone}
                onChange={formik.handleChange}
                label={Strings.checkoutPage.labels.phone}
                error={formik.touched.phone != null && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone != null && formik.errors.phone}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={'company'}
                value={formik.values.company}
                onChange={formik.handleChange}
                label={Strings.checkoutPage.labels.company}
                error={formik.touched.company != null && Boolean(formik.errors.company)}
                helperText={formik.touched.company != null && formik.errors.company}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type={'password'}
                name={'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                label={Strings.checkoutPage.labels.password}
                error={formik.touched.password != null && Boolean(formik.errors.password)}
                helperText={formik.touched.password != null && formik.errors.password}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography>{Strings.checkoutPage.title}</Typography>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              type={'submit'}
            >
              {Strings.checkoutPage.pay}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default CheckoutPlans