import React from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {Strings} from '../../resources/Strings'
import {Button} from '@mui/material'

interface DeleteButtonProps {
  message: string
  onConfirm: () => void
}
const DeleteButton: React.FC<DeleteButtonProps> = ({message, onConfirm}) => {
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
    <IconButton
      onClick={handleClickOpen}
      className="btn btn-danger"
    >
      <DeleteIcon
        htmlColor={'primary.dark'}
      />
    </IconButton>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{Strings.app.delete}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={'outlined'}
          onClick={handleClose}
        >
          {Strings.buttons.no}
        </Button>
        <Button
          variant={'contained'}
          color={'secondary'}
          onClick={() => {
            onConfirm()
            handleClose()
          }}
          autoFocus
        >
          {Strings.buttons.yes}
        </Button>
      </DialogActions>
    </Dialog>
    </>
  )
}
export default DeleteButton
