import React, {useEffect} from 'react'
import BaseLayout from '../../layout/BaseLayout'
import Box from '@mui/material/Box'
import {Button, Card, CardMedia, Grid, MenuItem, TextField} from '@mui/material'
import Loading from '../../components/UI/Loading'
import BackButton from '../../components/buttons/BackButton'
import {Strings} from '../../resources/Strings'
import {CompanyFormValidation} from '../../utils/CompanyFormValidation'
import {paths} from '../../resources/paths'
import {useFormik} from 'formik'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../redux/hooks'
import * as industriesApis from '../../config/api/industriesApiCalls'
import * as NotificationToast from '../../components/UI/ToastNotifications'
import * as companiesApis from '../../config/api/companiesApiCalls'
import {CompaniesType} from '../../types/CompaniesType'
import {IndustryType} from '../../types/IndustryType'
import UploadImageButton from '../../components/buttons/UploadImageButton'
import {resetImageUrl} from '../../redux/slices/imagesSlice'
import {addOrEditCompanyThunk} from '../../redux/thunks/addOrEditCompanyThunk'
const CompanyForm = () => {
  const {id} = useParams()
  const dispatch = useAppDispatch()
  const CREATE_COMPANY_PATH = 'new'
  const navigate = useNavigate()
  const {accessToken} = useAppSelector(state => state.authReducer)
  const {imageUrl, hasInputChanged} = useAppSelector(state => state.imageReducer)
  const [isEditing, setIsEditing] = React.useState<boolean>(false)
  const [industries, setIndustries] = React.useState<IndustryType[]>([])
  const [uploadImage, setUploadImage] = React.useState<boolean>(false)
  const [companyValues, setCompanyValues] = React.useState<CompaniesType>({
    comp_name: '',
    comp_url: '',
    comp_address: '',
    comp_country: '',
    comp_email: '',
    comp_industry: '',
    comp_post_code: '',
    comp_phone: '',
    comp_size: '',
    comp_linkedin: '',
    comp_profile_url: ''
  })
  const formik = useFormik<CompaniesType>({
    initialValues: companyValues,
    validationSchema: CompanyFormValidation,
    enableReinitialize: true,
    onSubmit: async (values: CompaniesType, {resetForm}: { resetForm: () => void }) => {
      if (hasInputChanged) {
        handleUploadImage()
        setCompanyValues(values)
      } else {
        try {
          if(accessToken){
            await dispatch(addOrEditCompanyThunk({values, isEditing, id, imageUrl, hasInputChanged, accessToken}))
            setTimeout(() => {
              navigate(paths.companies.basePath)
            }, 1500)
          }
        } catch (e) {
            console.log(e)
        }
      }
      resetForm()
    }
  })
  useEffect(() => {
    if (id !== CREATE_COMPANY_PATH && id !== undefined && accessToken) {
      setIsEditing(true)
      companiesApis.getCompanyById(id, accessToken)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            setCompanyValues({
              ...companyValues,
              comp_name: response.data.comp_name,
              comp_url: response.data.comp_url,
              comp_address: response.data.comp_address,
              comp_country: response.data.comp_country,
              comp_email: response.data.comp_email,
              comp_industry: response.data.comp_industry,
              comp_post_code: response.data.comp_post_code,
              comp_phone: response.data.comp_phone,
              comp_size: response.data.comp_size,
              comp_linkedin: response.data.comp_linkedin,
              comp_profile_url: response.data.comp_profile_url
            })
          }
        })
        .catch((error) => {
          NotificationToast.errorToast(error.message)
        })
    }
  }, [id, accessToken])
  useEffect(() => {
    if (accessToken) {
      try {
        industriesApis.getIndustries(accessToken)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              setIndustries(response.data)
            }
          })
          .catch((error) => {
            NotificationToast.errorToast(error.message)
          })
      } catch (e) {
        console.log(e)
      }
    }
  }, [accessToken])
  useEffect(() => {
    if (imageUrl.length > 0 && accessToken) {
      const values: CompaniesType = {...companyValues}
      dispatch(addOrEditCompanyThunk({values, isEditing, id, imageUrl, hasInputChanged, accessToken}))
        .then(() => {
          setUploadImage(false)
          dispatch(resetImageUrl(''))
          setTimeout(() => {
            navigate(paths.companies.basePath)
          }, 1500)
        })
        .catch((error: Error) => {
          NotificationToast.errorToast(error.message)
        })
    }
  }, [imageUrl])
  const handleUploadImage = () => {
    setUploadImage(true)
  }
  console.log(industries)
  return (
    <BaseLayout>
      <Box
        component={'form'}
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid container alignItems={'center'} item justifyContent={'space-between'}>
            <Grid item>
              <h2>{id !== CREATE_COMPANY_PATH ? Strings.companyPage.editCompany : Strings.companyPage.companyForm.title}</h2>
            </Grid>
            <Grid item>
              <BackButton/>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name={'comp_name'}
              label={Strings.companyPage.companyForm.name}
              value={formik.values.comp_name}
              onChange={formik.handleChange}
              error={formik.touched.comp_name != null && Boolean(formik.errors.comp_name)}
              helperText={formik.touched.comp_name != null && formik.errors.comp_name}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadImageButton
              text={Strings.images.uploadImage}
              type={Strings.images.type}
              message={Strings.images.imageUploadQuestion}
              canUpload={uploadImage}
            />
          </Grid>
          {isEditing && companyValues.comp_profile_url !== '' &&
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <Card>
                  <CardMedia
                    style={{justifyContent: 'center', display: 'flex'}}
                    component={'img'}
                    image={companyValues.comp_profile_url}
                    height={'100%'}
                    width={'100%'}
                  />
                </Card>
              </Grid>
            </Grid>
          }
          {industries.length === 0
            ? <Loading/>
            : <Grid container spacing={2} item xs={12}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  variant="filled"
                  name={'comp_industry'}
                  label={Strings.companyPage.companyForm.industry}
                  onChange={formik.handleChange}
                  value={formik.values.comp_industry}
                >
                  {industries.map((option) => (
                    <MenuItem key={option.ind_id} value={option.ind_name}>
                      {option.ind_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          }
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={Strings.companyPage.companyForm.address}
              name={'comp_address'}
              value={formik.values.comp_address}
              onChange={formik.handleChange}
              error={formik.touched.comp_address != null && Boolean(formik.errors.comp_address)}
              helperText={formik.touched.comp_address != null && formik.errors.comp_address}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              label={Strings.companyPage.companyForm.comp_profile_url}
              name={'comp_profile_url'}
              value={formik.values.comp_profile_url}
              onChange={formik.handleChange}
              error={formik.touched.comp_profile_url != null && Boolean(formik.errors.comp_profile_url)}
              helperText={formik.touched.comp_profile_url != null && formik.errors.comp_profile_url}
            />
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={Strings.companyPage.companyForm.companyUrl}
              name={'comp_url'}
              value={formik.values.comp_url}
              onChange={formik.handleChange}
              error={formik.touched.comp_url != null && Boolean(formik.errors.comp_url)}
              helperText={formik.touched.comp_url != null && formik.errors.comp_url}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={Strings.companyPage.companyForm.postalCode}
              name={'comp_post_code'}
              value={formik.values.comp_post_code}
              onChange={formik.handleChange}
              error={formik.touched.comp_post_code != null && Boolean(formik.errors.comp_post_code)}
              helperText={formik.touched.comp_post_code != null && formik.errors.comp_post_code}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={Strings.companyPage.companyForm.companyCountry}
              name={'comp_country'}
              value={formik.values.comp_country}
              onChange={formik.handleChange}
              error={formik.touched.comp_country != null && Boolean(formik.errors.comp_country)}
              helperText={formik.touched.comp_country != null && formik.errors.comp_country}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type={'number'}
              fullWidth
              label={Strings.companyPage.companyForm.companySize}
              name={'comp_size'}
              value={formik.values.comp_size}
              onChange={formik.handleChange}
              error={formik.touched.comp_size != null && Boolean(formik.errors.comp_size)}
              helperText={formik.touched.comp_size != null && formik.errors.comp_size}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={Strings.companyPage.companyForm.companyEmail}
              name={'comp_email'}
              value={formik.values.comp_email}
              onChange={formik.handleChange}
              error={formik.touched.comp_email != null && Boolean(formik.errors.comp_email)}
              helperText={formik.touched.comp_email != null && formik.errors.comp_email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={Strings.companyPage.companyForm.companyPhone}
              name={'comp_phone'}
              value={formik.values.comp_phone}
              onChange={formik.handleChange}
              error={formik.touched.comp_phone != null && Boolean(formik.errors.comp_phone)}
              helperText={formik.touched.comp_phone != null && formik.errors.comp_phone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={Strings.companyPage.companyForm.companyLinkedIn}
              name={'comp_linkedin'}
              value={formik.values.comp_linkedin}
              onChange={formik.handleChange}
              error={formik.touched.comp_linkedin != null && Boolean(formik.errors.comp_linkedin)}
              helperText={formik.touched.comp_linkedin != null && formik.errors.comp_linkedin}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color={'secondary'}
              fullWidth
              variant={'contained'}
              type={'submit'}
            >{id !== CREATE_COMPANY_PATH ? Strings.companyPage.editCompany : Strings.companyPage.addCompany}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </BaseLayout>
  )
}

export default CompanyForm
