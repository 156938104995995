import {Container, Grid} from '@mui/material'
import CheckoutPlans from '../../components/products/CheckoutPlans'
import Typography from '@mui/material/Typography'
import {Strings} from '../../resources/Strings'
import CheckoutPlansFeatures from '../../components/products/CheckoutPlansFeatures'
import {useEffect, useState} from 'react'
import {plansListWithFeatures} from '../../resources/plansListWithFeatures'
const Checkout = () => {
  const [planSelected, setPlanSelected] = useState<string>('')
  const [planToDisplay, setPlanToDisplay] = useState<any>({
    name: '',
    description: '',
    price: 0,
    credits: 0,
    additionalCreditsPrice: 0,
    features: []
  })
  const handleChangePlan = (plan: string) => {
    setPlanSelected(plan)
  }
  useEffect(() => {
    if (planSelected !== '') {
      setPlanToDisplay(plansListWithFeatures.find((plan) => plan.name === planSelected))
    }
  }, [planSelected])
    return (
        <Grid container>
            <Grid item xs={8}>
              <Container>
                <Typography variant="h6" gutterBottom>{Strings.checkoutPage.title}</Typography>
                <CheckoutPlans changePlan={handleChangePlan}/>
              </Container>
            </Grid>
            
            <Grid item xs={4} sx={{marginTop: 7}}>
              <Container>
                <CheckoutPlansFeatures
                  plan={planToDisplay}
                />
              </Container>
            </Grid>
        </Grid>
    )
}

export default Checkout